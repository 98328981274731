import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TariffFeaturesPremium } from '../../../../web/tariff/features/premium/tariff-features-premium';
import { Wrapper, More } from './offboarding-step1.styled';
import { Button } from '../../../form/button/button';
import { routes } from '../../../../../config/routes';

export const OffBoardingStep1: React.FC = observer(() => {
    const { t } = useTranslation();
    return (
        <>
            <Wrapper>
                <TariffFeaturesPremium />
                <More>
                    <Button
                        href={routes.web.pricing}
                        variant="purple"
                        shadow
                        gradient
                        block
                        size={{ xs: 'vsmall', md: 'medium' }}
                    >
                        {t('dialogs.offboarding.step1.compareTariffs')}
                    </Button>
                </More>
            </Wrapper>
        </>
    );
});
