import React from 'react';
import { observer } from 'mobx-react-lite';
import { PortfolioMobxDto } from '../../../../../../models/mobx/dtos/portfolio/portfolio-mobx-dto';
import { Main, Info, Content, Name, Value } from './portfolios-list-item-simple.styled';
import { IconPortfolio } from '../../../../../../components/app/icon/portfolio/icon-portfolio';
import { ValueAmount } from '../../../../../../components/app/value/amount/value-amount';

type PortfoliosListItemType = {
    data: PortfolioMobxDto;
    disabled: boolean;
};

export const PortfoliosListItemSimple: React.FC<PortfoliosListItemType> = observer(
    ({ data, disabled = false }: PortfoliosListItemType) => {
        return (
            <>
                <Main disabled={disabled}>
                    <IconPortfolio type={data.iconName} variant={data.colorName} size={'small'} />
                    <Content>
                        <Info>
                            <Name>{data.name}</Name>
                        </Info>

                        <Value>
                            <ValueAmount
                                amount={data.portfolioValue}
                                currency={data.portfolioHistoryCurrencyCode || data.portfolioHistoryCurrencyCode}
                                mode="pure"
                                locked={disabled}
                                hidable
                            />
                        </Value>
                    </Content>
                </Main>
            </>
        );
    },
);
