import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../../../form/button/button';
import { Stack } from '../../../../stack/stack';
import { Badge } from './offboarding-step5-action.styled';
import { useRootStore } from '../../../../../../providers/root-store-provider';

type OffBoardingStep5ActionProps = {
    onContinue: () => void;
    onClose: () => void;
};

export const OffBoardingStep5Action: React.FC<OffBoardingStep5ActionProps> = observer(
    ({ onContinue, onClose }: OffBoardingStep5ActionProps) => {
        const { t } = useTranslation();
        const { offboardingStore } = useRootStore();

        return (
            <Stack direction="vertical" alignItems="center" fullWidth>
                <Button
                    variant="positive"
                    block
                    variation="filled"
                    size="large"
                    onClick={onClose}
                    loading={offboardingStore.loading}
                >
                    <Trans
                        i18nKey={'dialogs.offboarding.step5.button.cancel'}
                        components={{ badge: <Badge>0</Badge> }}
                    />
                </Button>
                <Button loading={offboardingStore.cancelLoading} variant={'white'} onClick={onContinue}>
                    {t('dialogs.offboarding.step5.button.continue')}
                </Button>
            </Stack>
        );
    },
);
