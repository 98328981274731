import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { PortfolioMobxDto } from '../../../../../../../models/mobx/dtos/portfolio/portfolio-mobx-dto';
import { Wrapper, Badge } from './portfolios-list-item-simple-summary.styled';
import { PortfoliosListItemSimple } from '../portfolios-list-item-simple';
import { useRootStore } from '../../../../../../../providers/root-store-provider';

type PortfoliosListItemSummaryType = {
    data: PortfolioMobxDto;
    disabled: boolean;
    onClick?: () => void;
};

export const PortfoliosListItemSimpleSummary: React.FC<PortfoliosListItemSummaryType> = observer(
    ({ data, disabled = false, onClick }: PortfoliosListItemSummaryType) => {
        const { themeStore } = useRootStore();
        const { t } = useTranslation();

        return (
            <Wrapper
                isActive={data.selected}
                variant={data.colorName}
                selectedTheme={themeStore.theme}
                onClick={onClick}
            >
                <PortfoliosListItemSimple disabled={disabled} data={data} />
                <Badge variant={data.colorName}>{t('layout.tables.portfolios.summaryPortfolioShort')}</Badge>
            </Wrapper>
        );
    },
);
