import styled, { css } from 'styled-components';

export const Badge = styled.div`
    ${({ theme }) => css`
        padding: 0.7rem 1.4rem;
        display: inline-flex;
        margin-left: 1rem;
        background-color: ${theme.palette.color.white.opacity.low};
        color: ${theme.palette.color.positive.dark};
        border-radius: 10rem;
    `}
`;

export const CancelPremium = styled.button`
    ${({ theme }) => css`
        color: ${theme.palette.color.default.main};
        cursor: pointer;
        border: unset;
        background: unset;
        margin-top: ${theme.gap.small};
        opacity: 0.7;
        transition: opacity ${theme.base.transition};

        &:hover {
            opacity: 1;
        }
    `}
`;
