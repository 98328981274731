import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../../theme/utils/get-rgb';
import { Wrapper as Headline } from '../../../headline/headline.styled';

export const PricingPaymentTypeWrapper = styled.div(
    ({ theme }) => css`
        > div {
            margin-bottom: 0;
        }

        @media ${theme.breakpoints.md.max} {
            margin-top: -1.5rem;
        }

        @media ${theme.breakpoints.lg.min} {
            transform: scale(0.9);
            margin-top: -7rem;
        }
    `,
);

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive', 'isDarkTheme'].includes(prop),
})<{
    isActive: boolean;
    isDarkTheme: boolean;
}>(
    ({ theme, isActive, isDarkTheme }) => css`
        border-radius: 2.4rem;
        width: 100%;
        background: ${theme.palette.background.header};
        width: 38rem;

        ${!isDarkTheme &&
        css`
            box-shadow: 0 0 1rem 0.5rem ${getOpacity(theme.palette.color.secondary.main, 0.07)};
        `}

        ${isDarkTheme &&
        css`
            box-shadow: 0 0 1rem 0.5rem ${getOpacity(theme.palette.color.black.main, 0.1)};
        `}


        ${isActive &&
        css`
            box-shadow: 0 0 0 0.5rem ${getOpacity(theme.palette.color.primary.main, 0.1)},
                0 0 1rem 1rem ${getOpacity(theme.palette.color.secondary.main, 0.1)};
            background: ${theme.palette.background.header};
        `}

        @media ${theme.breakpoints.lg.max} {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            max-width: 36rem;
        }
    `,
);

export const Inner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['premium', 'isActive'].includes(prop),
})<{
    premium: boolean;
    isActive: boolean;
}>(
    ({ theme, premium, isActive }) => css`
        padding: 4rem 2rem;
        border-radius: 2.4rem;

        @media ${theme.breakpoints.sm.min} {
            padding: 6rem 4rem;
        }

        background: linear-gradient(
            to bottom,
            ${theme.palette.background.header} 0%,
            ${getOpacity(theme.palette.color.primary.light, 0.04)} 100%
        );

        ${premium &&
        css`
            background: linear-gradient(
                to bottom,
                ${getOpacity(theme.palette.color.primary.main, 0.04)} 0%,
                ${getOpacity(theme.palette.color.primary.main, 0)} 100%
            );
        `}

        ${isActive &&
        css`
            background: ${theme.palette.background.header};
        `}
    `,
);

export const Header = styled.div.withConfig({
    shouldForwardProp: (prop) => !['premium'].includes(prop),
})<{
    premium: boolean;
}>(
    ({ theme, premium }) => css`
        display: flex;
        align-items: center;
        margin-bottom: 3.2rem;

        @media (max-width: 768px) {
            margin-bottom: 2.4rem;
        }

        ${premium &&
        css`
            ${Headline} {
                color: ${theme.palette.color.primary.dark};
            }
        `};
    `,
);

export const Line = styled.div(
    ({ theme }) => css`
        width: 100%;
        height: 1px;
        background: ${theme.palette.color.gray.opacity.high};
        display: inline-block;
        margin: 0 1.6rem;
    `,
);

export const Chip = styled.div(
    ({ theme }) => css`
        font-size: 1.2rem;
        line-height: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        padding: 1rem 1.6rem;
        background: ${theme.palette.color.positive.main};
        color: ${theme.palette.color.white.main};
        border-radius: 5rem;
    `,
);

export const Prices = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-bottom: 2.4rem;
`;

export const Footer = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.4rem;
        margin-top: 3.8rem;
        text-align: center;

        button {
            margin: 0 auto;
        }

        p {
            font-size: 1.4rem;
            line-height: 1.4rem;
            color: ${theme.palette.color.gray.opacity.low};
        }
    `,
);
