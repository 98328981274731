import React from 'react';
import { observer } from 'mobx-react-lite';
import { StornoReasonEnum } from '@app/common';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../form/button/button';
import { useRootStore } from '../../../../../../providers/root-store-provider';

type OffBoardingStep2ActionProps = {
    onContinue: () => void;
    onCancel: () => void;
};

export const OffBoardingStep2Action: React.FC<OffBoardingStep2ActionProps> = observer(
    ({ onContinue, onCancel }: OffBoardingStep2ActionProps) => {
        const { t } = useTranslation();
        const { offboardingStore } = useRootStore();

        const isFieldValueEmpty =
            (offboardingStore.stornoReasonDescription.length === 0 &&
                offboardingStore.stornoReason === StornoReasonEnum.Other) ||
            (offboardingStore.stornoReasonDescription.length === 0 &&
                offboardingStore.stornoReason === StornoReasonEnum.UsingAlternativeService);
        const disabled = !offboardingStore.stornoReason || isFieldValueEmpty;

        return (
            <>
                <Button
                    variant="negative"
                    disabled={disabled}
                    variation="outlined"
                    onClick={onContinue}
                    loading={offboardingStore.loading}
                >
                    {t('dialogs.offboarding.step2.button.continue')}
                </Button>
                <Button variant="positive" onClick={onCancel}>
                    {t('dialogs.offboarding.step2.button.cancel')}
                </Button>
            </>
        );
    },
);
