import styled, { css } from 'styled-components';

export const Title = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.color.white.main};
        font-size: 3.8rem;
        line-height: 4.6rem;
        font-weight: 700;
        margin-bottom: ${theme.gap.medium};
        max-width: 36rem;

        @media ${theme.breakpoints.lg.max} {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    `}
`;

export const Description = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.color.white.main};
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 700;
        margin-bottom: ${theme.gap.medium};
        max-width: 28rem;

        @media ${theme.breakpoints.lg.max} {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    `}
`;

export const Action = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media ${theme.breakpoints.vl.min} {
            align-items: center;
            flex-direction: row;
        }

        @media ${theme.breakpoints.lg.max} {
            margin-bottom: ${theme.gap.medium};
        }
    `}
`;
