import * as React from 'react';
import { LogoPortfolioWrapper, Wrapper } from '../header-mobile.styled';
import { routes } from '../../../../../config/routes';
import { Logo } from '../../../../../components/app/logo/logo';
import { HeaderBackButton } from '../../../../../components/app/header/back-button/header-back-button';

export const HeaderMobileShared: React.FC = () => {
    return (
        <Wrapper>
            <LogoPortfolioWrapper>
                <Logo href={routes.app.index} inApp />
            </LogoPortfolioWrapper>
            <HeaderBackButton />
        </Wrapper>
    );
};
