import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Main, Header, Name, Description, ExtraContent, More } from './info-box.styled';
import { IconArrowDown } from '../icons/arrow-down';
import { IconArrowUp } from '../icons/arrow-up';

type InfoBoxType = {
    name: string;
    more?: string;
    description?: React.ReactNode;
    extraContent?: React.ReactNode;
    image?: string;
    dark?: boolean;
};
export const InfoBox: React.FC<InfoBoxType> = ({ name, more, description, extraContent, image, dark = false }) => {
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState<boolean>(false);

    return (
        <Wrapper dark={dark} image={image}>
            <Main description={!description}>
                <Header>
                    <Name>{name}</Name>
                    {more && (
                        <More onClick={() => setIsOpened(!isOpened)}>
                            {isOpened ? <IconArrowUp /> : <IconArrowDown />}

                            {isOpened ? t('infoBox.close') : more}
                        </More>
                    )}
                </Header>
                {description ? <Description>{description}</Description> : null}
            </Main>
            {extraContent && isOpened && <ExtraContent>{extraContent}</ExtraContent>}
        </Wrapper>
    );
};
