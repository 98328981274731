import * as React from 'react';
import { LogoPortfolioWrapper, Wrapper } from './header-vertical.styled';
import { Navigation } from '../../navigation/navigation';
import { AccountBox } from '../../account-box/account-box';
import { routes } from '../../../../config/routes';
import { VerticalDivider } from '../header.styled';
import { PortfolioSwitcher } from '../../../../components/app/portfolio/switcher/portfolio-switcher';
import { Logo } from '../../../../components/app/logo/logo';

export const HeaderVertical: React.FC = () => {
    return (
        <Wrapper>
            <LogoPortfolioWrapper>
                <Logo href={routes.app.index} inApp />
                <VerticalDivider />
                <PortfolioSwitcher />
            </LogoPortfolioWrapper>
            <Navigation />
            <AccountBox />
        </Wrapper>
    );
};
