import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import { InfoBox } from '../info-box';
import { Text } from '../../text/text';
import { Column, Number, Row } from '../info-box.styled';

export const InfoBoxReportError: React.FC = () => {
    const { t } = useTranslation();

    return (
        <InfoBox
            dark
            image={'/images/info-box/report-error.png'}
            name={t('infoBox.reportError.name')}
            more={t('infoBox.reportError.more')}
            extraContent={
                <>
                    <Row>
                        <Column>
                            <Number>1.</Number>
                            <Text variant={'inverse'} size={'vlarge'} semiBold>
                                <Trans i18nKey="infoBox.reportError.extra.01" components={{ strong: <strong /> }} />
                            </Text>
                        </Column>

                        <Column>
                            <Number>2.</Number>
                            <Text variant={'inverse'} size={'vlarge'} semiBold>
                                <Trans
                                    i18nKey="infoBox.reportError.extra.02"
                                    components={{
                                        strong: <strong />,
                                        newTicket: (
                                            <a
                                                href={process.env.NEXT_PUBLIC_SUPPORT_NEW_TICKET_URL}
                                                target={'_blank'}
                                                rel="noreferrer"
                                            />
                                        ),
                                    }}
                                />
                            </Text>
                        </Column>
                    </Row>

                    <Image
                        src={'/images/info-box/report-error-image.png'}
                        width={1032}
                        height={365}
                        layout={'responsive'}
                    />
                </>
            }
        />
    );
};
