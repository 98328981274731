import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InfoBox } from '../info-box';

export const InfoBoxRealizedProfit: React.FC = () => {
    const { t } = useTranslation();

    return (
        <InfoBox
            image={'/images/info-box/realized-profit.png'}
            name={t('infoBox.realizedProfit.name')}
            description={<Trans i18nKey="infoBox.realizedProfit.description" components={{ strong: <strong /> }} />}
        />
    );
};
