import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{
    show?: boolean;
}>`
    ${({ theme, show }) => css`
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        padding: 1rem;
        transition: all ${theme.base.transition};
        visibility: hidden;
        z-index: 1000;
        backdrop-filter: blur(0.5rem);
        align-items: center;
        display: flex;
        justify-content: center;

        ${show &&
        css`
            opacity: 1;
            visibility: visible;
        `}

        @media ${theme.breakpoints.md.min} {
            padding: 2rem;
        }
    `}
`;

export const Overlay = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{
    show?: boolean;
}>`
    ${({ theme }) => css`
        background-color: ${theme.modal.overlay.background};
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1100;
    `}
`;

export const Window = styled.div.withConfig({
    shouldForwardProp: (prop) => !['width'].includes(prop),
})<{
    width?: number;
}>`
    ${({ theme, width }) => css`
        background-color: ${theme.palette.color.primary.main};
        background-image: url('/images/modals/locked-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        max-width: ${width ? `${width}rem` : '88rem'};
        padding: 2rem;
        position: relative;
        width: 100%;
        z-index: 1200;
        max-height: 100%;
        overflow: auto;
        border-radius: ${theme.radius.xlarge};
        color: ${theme.palette.color.white.main};

        @media ${theme.breakpoints.md.min} {
            padding: 4rem;
            height: unset;
        }

        @media ${theme.breakpoints.lg.min} {
            overflow: visible;
            padding: 5rem 5rem 5rem 42rem;
            margin-top: 10rem;
        }
    `}
`;

export const Close = styled.div.withConfig({
    shouldForwardProp: (prop) => !['locked'].includes(prop),
})<{
    locked: boolean;
}>`
    ${({ theme, locked }) => css`
        cursor: pointer;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        opacity: 0.4;

        i {
            color: ${theme.modal.close.base.color};
            height: 2rem;
            transition: color ${theme.base.transition};
            width: 2rem;
        }

        &:hover {
            opacity: 0.7;
            i {
                color: ${theme.modal.close.hover.color};
            }
        }

        ${locked &&
        css`
            i {
                color: ${theme.palette.color.white.main};
            }

            &:hover {
                i {
                    color: ${theme.palette.color.white.main};
                }
            }
        `}
    `}
`;

export const TariffWrapper = styled.div`
    ${({ theme }) => css`

        margin-top: 7rem;

        @media ${theme.breakpoints.sm.max} {
            margin-left: -2rem;
            margin-right: -2rem;
            margin-bottom: -2rem;
        }

        @media ${theme.breakpoints.md.min} {
            margin-top: 15rem;
        }

        @media ${theme.breakpoints.lg.min} {
            min-width: 38rem;
            max-width: 38rem;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: unset;
            transform: translate(0, -50%);

    `}
`;
