import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: row;
        gap: 1rem;

        @media ${theme.breakpoints.vl.min} {
            position: fixed;
            bottom: ${theme.gap.medium};
            left: ${theme.gap.medium};
            flex-direction: column;
            z-index: 910;
        }
    `,
);
