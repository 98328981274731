import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton } from '../../../../../components/app/icon/button/icon-button';
import { routes } from '../../../../../config/routes';
import { IconWarningFilled } from '../../../../../components/app/icons/warning-filled';
import { Dropdown } from '../../../../../components/app/dropdown/dropdown';
import { DropdownMenu } from '../../../../../components/app/dropdown/menu/dropdown-menu';
import { Alert } from '../../../../../components/app/alert/alert';
import { Button } from '../../../../../components/app/form/button/button';
import { IconRefresh } from '../../../../../components/app/icons/refresh';
import { useRootStore } from '../../../../../providers/root-store-provider';

export const HeaderButtonWarning: React.FC = () => {
    const { t } = useTranslation();

    const router = useRouter();

    const rootStore = useRootStore();
    const { portfolioStore, tradeStore } = rootStore;

    const isTradesPage = router.pathname === routes.app.trades;

    const recalculateTrades = useCallback(async () => {
        await router.push(routes.app.trades);
        await tradeStore.recalculateUnprocessedTrades();
        await portfolioStore.fetchPortfolioList();
    }, []);

    const dropdownContent = useMemo(() => {
        return (
            <DropdownMenu>
                <>
                    <Alert type="warning" icon>
                        <Trans i18nKey="pages.trades.notRecalculatedInDropdown" components={{ br: <br /> }} />
                    </Alert>
                    <Button
                        disabled={portfolioStore.selectedPortfolio.isRecalculating}
                        variant="default"
                        onClick={recalculateTrades}
                        icon={<IconRefresh />}
                        block
                    >
                        {t('pages.trades.recalculate')}
                    </Button>
                </>
            </DropdownMenu>
        );
    }, [portfolioStore.selectedPortfolio.isRecalculating, tradeStore]);

    return !isTradesPage &&
        !portfolioStore.selectedPortfolio.processed &&
        !portfolioStore.selectedPortfolio.isRecalculating ? (
        <Dropdown content={dropdownContent}>
            <IconButton size={{ xs: 'xsmall', xl: 'medium' }} icon={<IconWarningFilled />} variant={'warning'} />
        </Dropdown>
    ) : null;
};
