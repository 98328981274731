import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconPrev: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <line
            x1="23.25"
            y1="12"
            x2="0.75"
            y2="12"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <polyline
            points="11.25 1.5 0.75 12 11.25 22.5"
            fillRule="evenodd"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
    </Icon>
);
