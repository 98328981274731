import * as React from 'react';
import { LayoutEnum } from '@app/common';
import { Wrapper } from './header-shared.styled';
import { useRootStore } from '../../../../providers/root-store-provider';
import { HeaderHorizontalShared } from '../horizontal/shared/header-horizontal-shared';
import { HeaderVerticalShared } from '../vertical/shared/header-vertical-shared';
import { Responsive } from '../../../../components/app/responsive/responsive';
import { HeaderMobileShared } from '../mobile/shared/header-mobile-shared';

export const HeaderShared: React.FC = () => {
    const rootStore = useRootStore();
    const { layout, showBar } = rootStore.themeStore;
    return (
        <Wrapper layout={layout} showBar={showBar}>
            <Responsive breakpoint="vl" type="max">
                <HeaderMobileShared />
            </Responsive>
            <Responsive breakpoint="vl">
                <>
                    {layout === LayoutEnum.Horizontal && <HeaderHorizontalShared />}
                    {layout === LayoutEnum.Vertical && <HeaderVerticalShared />}
                </>
            </Responsive>
        </Wrapper>
    );
};
