import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Content, ContentInner, Overlay, Wrapper, Bottom } from './layout.styled';
import { useRootStore } from '../../../providers/root-store-provider';
import { GetPremiumDialog } from '../../../components/app/dialogs/get-premium/get-premium-dialog';
import { routes } from '../../../config/routes';
import { Header } from '../header/header';
import { ImpersonationDialog } from '../../../components/web/dialogs/account/impersonation-dialog';
import { Bar } from '../../../components/app/bar/bar';
import { Container } from '../../../components/app/container/container';
import { Alerts } from '../../../components/app/alerts/alerts';
import { ThemeSettings } from '../../../components/app/theme-settings/theme-settings';
import { SearchSymbolDialog } from '../../../components/app/dialogs/symbol/search-symbol-dialog/search-symbol-dialog';
import { OffBoardingDialog } from '../../../components/app/dialogs/offboarding/offboarding-dialog';
import { HeaderShared } from '../header/shared/header-shared';
import { PortfolioValueCalculationInfoDialog } from '../../../components/app/dialogs/portfolio-value-calculation-info/portfolio-value-calculation-info';
import { FloatingButtons } from '../../../components/app/floating-buttons/floating-buttons';
import { loadZendesk } from '../../../utils/load-zendesk';
import { TermsAgreementDialog } from '../../../components/app/dialogs/terms-agreement/terms-agreement-dialog';

interface LayoutPropsInterface {
    children: React.ReactNode | Array<React.ReactNode | null>;
}

export const Layout: React.FC<LayoutPropsInterface> = observer(({ children }) => {
    const rootStore = useRootStore();
    const {
        themeStore: { layout },
    } = rootStore;
    const router = useRouter();

    router.events.on('routeChangeComplete', () => {
        rootStore.premiumDialogStore.openAfterRouteChange();
    });

    const { t } = useTranslation();
    const isPortfolioListPage = router.pathname === routes.app.portfolioList;

    useEffect(() => {
        if (isPortfolioListPage) {
            return;
        }
        if (rootStore.portfolioStore.selectedPortfolio.isRecalculating) {
            rootStore.portfolioStore.waitForRecalculatingDone();
            if (rootStore.authStore.userHasFreeTariff) {
                rootStore.alertStore.setWarningMessage(t('layout.alerts.recalculatingPortfolioFreeTariff'));
            } else {
                rootStore.alertStore.setWarningMessage(t('layout.alerts.recalculatingPortfolio'));
            }
            rootStore.alertStore.setLoading(true);
        } else {
            rootStore.alertStore.setWarningMessage('');
            rootStore.alertStore.setLoading(false);
        }
    }, [rootStore.portfolioStore.selectedPortfolio.isRecalculating]);

    useEffect(() => {
        if (rootStore.authStore.userProfile?.zendeskToken && router.asPath.includes(routes.app.index)) {
            loadZendesk(rootStore.authStore.userProfile.zendeskToken);
        }
    }, [rootStore.authStore.userProfile, router.asPath]);

    return (
        <>
            {rootStore.themeStore.showBar && <Bar />}
            <Wrapper type={layout}>
                {rootStore.portfolioStore.sharedPortfolio ? <HeaderShared /> : <Header />}

                <Content type={layout}>
                    <ContentInner showBar={rootStore.themeStore.showBar}>
                        <Container>
                            <Alerts />
                            {children}
                        </Container>
                    </ContentInner>
                </Content>

                {(rootStore.themeStore.mobileNavigationDisplay ||
                    rootStore.themeStore.mobilePortfolioNavigationDisplay) && (
                    <Overlay
                        onClick={() => {
                            rootStore.themeStore.hideMobileNavigation();
                            rootStore.themeStore.hideMobilePortfolioNavigation();
                        }}
                    />
                )}
                <GetPremiumDialog />
                <ImpersonationDialog />
                <SearchSymbolDialog />
                <OffBoardingDialog />
                <PortfolioValueCalculationInfoDialog />
                <TermsAgreementDialog />
                <Bottom>
                    <ThemeSettings />
                    <FloatingButtons />
                </Bottom>
            </Wrapper>
        </>
    );
});
