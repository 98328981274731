import styled, { css } from 'styled-components';

export const Badge = styled.div`
    ${({ theme }) => css`
        padding: 0.7rem 1.4rem;
        display: inline-flex;
        margin-left: 1rem;
        background-color: ${theme.palette.color.white.opacity.low};
        color: ${theme.palette.color.positive.dark};
        border-radius: 10rem;
    `}
`;
