import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../config/routes';
import { Button } from '../../form/button/button';
import { IconPrev } from '../../icons/prev';

export const HeaderBackButton: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Button
            href={routes.app.index}
            size={{ xs: 'small', md: 'medium' }}
            icon={<IconPrev />}
            iconGap={'xsmall'}
            iconPosition={'left'}
            block
            variation="outlined"
        >
            {t('layout.navigation.admin.backToApp')}
        </Button>
    );
};
