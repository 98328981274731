import React from 'react';
import { Wrapper, Window, Overlay, Close, TariffWrapper } from './modal-premium.styled';
import { IconClose } from '../../icons/close';
import { ModalPremiumTariff } from './tariff/modal-premium-tariff';
import { useEscapeClose } from '../../../../hooks/use-escape-close';
import { ModalAlerts } from '../../alerts/modal/alerts';

type ModalPremiumType = {
    children: React.ReactNode;
    show?: boolean;
    title?: string;
    footer?: React.ReactNode;
    onClose?: () => void;
    loading?: boolean;
    locked?: boolean;
    width?: number;
};

export const ModalPremium: React.FC<ModalPremiumType> = ({
    show = false,
    children,
    onClose,
    locked = false,
    width,
}: ModalPremiumType) => {
    useEscapeClose(show, onClose);

    return show ? (
        <Wrapper show={show}>
            <Window width={width}>
                <ModalAlerts />

                {children}
                <TariffWrapper>
                    <ModalPremiumTariff />
                </TariffWrapper>
                <Close locked={locked} onClick={onClose}>
                    <IconClose />
                </Close>
            </Window>
            <Overlay />
        </Wrapper>
    ) : null;
};
