import React, { useState } from 'react';
import { Card } from '../card/card';
import { FloatingObject } from '../floating-object/floating-object';
import { PositionType } from '../../../utils/calculate-position';

type DropdownProps = {
    children: React.ReactNode;
    content: React.ReactNode | string;
    position?: PositionType;
    block?: boolean;
    size?: 'small' | 'medium';
    arrowPosition?: number;
};

export const Dropdown: React.FC<DropdownProps> = ({
    children,
    content,
    position = 'bottom-right',
    block = false,
    size = 'medium',
    arrowPosition,
}) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    return (
        <FloatingObject
            trigger={children}
            content={
                <Card padding="vsmall" shadow="medium">
                    <>{content}</>
                </Card>
            }
            position={position}
            isVisible={isDropdownVisible}
            setIsVisible={setIsDropdownVisible}
            arrowPosition={arrowPosition}
            block={block}
            size={size}
            protectiveZone={15}
        />
    );
};
