import * as React from 'react';
import { LayoutEnum } from '@app/common';
import { Wrapper } from './header.styled';
import { useRootStore } from '../../../providers/root-store-provider';
import { HeaderMobile } from './mobile/header-mobile';
import { HeaderVertical } from './vertical/header-vertical';
import { HeaderHorizontal } from './horizontal/header-horizontal';
import { Responsive } from '../../../components/app/responsive/responsive';

export const Header: React.FC = () => {
    const rootStore = useRootStore();
    const { layout, showBar } = rootStore.themeStore;
    return (
        <Wrapper layout={layout} showBar={showBar}>
            <Responsive breakpoint="vl" type="max">
                <HeaderMobile />
            </Responsive>
            <Responsive breakpoint="vl">
                <>
                    {layout === LayoutEnum.Horizontal && <HeaderHorizontal />}
                    {layout === LayoutEnum.Vertical && <HeaderVertical />}
                </>
            </Responsive>
        </Wrapper>
    );
};
