import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['showOnMobile'].includes(prop),
})<{
    showOnMobile: boolean;
}>(
    ({ theme, showOnMobile }) => css`
        display: ${showOnMobile ? 'block' : 'none'};
        position: absolute;
        top: 100%;
        background: ${theme.palette.background.header};
        left: 0;
        right: 0;
        box-shadow: inset 0px -1px 0px rgba(153, 153, 153, 0.16);
        border-top: 0.1rem solid ${theme.palette.common.border};
        padding-bottom: ${theme.gap.medium};
        overflow: auto;
        max-height: calc(100vh - 6.2rem);
    `,
);
