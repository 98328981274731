import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        padding: 0.8rem;
        padding-right: ${theme.gap.medium};
        border-radius: 99rem;
        position: relative;
    `}
`;

export const Main = styled.div.withConfig({
    shouldForwardProp: (prop) => !['disabled'].includes(prop),
})<{
    disabled: boolean;
}>`
    ${({ theme, disabled }) => css`
        display: flex;
        flex: 1;
        gap: ${theme.gap.xsmall};
        align-items: center;

        @media ${theme.breakpoints.lg.min} {
            gap: ${theme.gap.xsmall};
        }

        ${disabled &&
        css`
            opacity: 0.4;
            filter: blur(0.2rem);
        `}
    `}
`;

export const Info = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: ${theme.gap.xsmall};
    `}
`;

export const Content = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    `}
`;

export const Name = styled.strong`
    ${({ theme }) => css`
        font-size: ${theme.text.size.small.fontSize};
        opacity: 0.8;
    `}
`;

export const Value = styled.strong`
    ${({ theme }) => css`
        font-size: ${theme.text.size.small.fontSize};

        @media ${theme.breakpoints.lg.min} {
            font-size: ${theme.text.size.medium.fontSize};
        }
    `}
`;
