import React from 'react';
import { Button } from '../form/button/button';
import { Window, Wrapper, Action, Title, Content } from './confirm.styled';

interface ConfirmPropsInterface {
    children: React.ReactNode;
    isActive: boolean;
    isProcessing?: boolean;
    position?: 'left' | 'center' | 'right';
    positionY?: 'bottom' | 'top';
    title?: string;
    content?: string;
    confirmTitle?: string;
    cancelTitle?: string;
    onCancel: () => void;
    onConfirm: () => void;
    variant?: 'positive' | 'negative';
}

export const Confirm: React.FC<ConfirmPropsInterface> = ({
    children,
    isActive,
    isProcessing = false,
    position = 'left',
    title,
    content,
    confirmTitle,
    cancelTitle,
    onCancel,
    onConfirm,
    positionY,
    variant = 'negative',
}: ConfirmPropsInterface) => {
    return (
        <Wrapper isActive={isActive}>
            {children}
            {isActive && (
                <Window position={position} rounded={!title && !content} positionY={positionY}>
                    {title && <Title variant={variant}>{title}</Title>}
                    <Action>
                        <Button size="vsmall" variation="transparent" onClick={onCancel}>
                            {cancelTitle}
                        </Button>
                        <Button
                            loading={isProcessing}
                            variant={variant}
                            size="vsmall"
                            variation="filled"
                            onClick={onConfirm}
                        >
                            {confirmTitle}
                        </Button>
                    </Action>
                    {content && <Content>{content}</Content>}
                </Window>
            )}
        </Wrapper>
    );
};
