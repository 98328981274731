import React from 'react';
import { Wrapper } from './account-box.styled';
import { HamburgerMenu } from '../hamburger-menu/hamburger-menu';
import { Responsive } from '../../../components/app/responsive/responsive';
import { Account } from '../../../components/app/account/account';
import { HeaderButtonWatchlist } from '../header/button/watchlist/header-button-watchlist';
import { HeaderButtonSearch } from '../header/button/search/header-button-search';
import { HeaderButtonWarning } from '../header/button/warning/header-button-warning';
import { HeaderButtonImpersonation } from '../header/button/impersonation/header-button-impersonation';

type AccountBoxType = {
    search?: boolean;
    watchlist?: boolean;
};

export const AccountBox: React.FC<AccountBoxType> = ({ search = true, watchlist = true }) => {
    return (
        <Wrapper>
            <HeaderButtonImpersonation />

            <HeaderButtonWarning />

            {search && <HeaderButtonSearch />}

            {watchlist && <HeaderButtonWatchlist />}

            <Responsive breakpoint="vl">
                <Account />
            </Responsive>

            <HamburgerMenu />
        </Wrapper>
    );
};
