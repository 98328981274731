import * as React from 'react';
import { TableAction } from '../table-action';
import { IconArrowRightFull } from '../../../icons/arrow-right-full';

export interface TableActionPropsInterface {
    onClick: () => void;
}

export interface ActionPropsInterface {
    actionOnClick: () => void;
    disabled?: boolean;
}

export const TableActionGoToSymbolDetail: React.FC<TableActionPropsInterface> = ({ onClick }) => {
    const Action: React.FC<ActionPropsInterface> = ({ actionOnClick, disabled }) => (
        <TableAction icon={<IconArrowRightFull />} onClick={actionOnClick} disabled={disabled} />
    );

    return <Action actionOnClick={onClick} />;
};
