import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/root-store-provider';
import { Dropdown } from '../dropdown/dropdown';
import { Avatar } from '../avatar/avatar';
import { DropdownMenu } from '../dropdown/menu/dropdown-menu';
import { DropdownMenuItem } from '../dropdown/menu/item/dropdown-menu-item';
import { routes } from '../../../config/routes';
import { IconUser } from '../icons/user';
import { IconSettings } from '../icons/settings';
import { IconImpersonate } from '../icons/impersonate';
import { IconLogout } from '../icons/logout';
import { IconButton } from '../icon/button/icon-button';

export const Account: React.FC = observer(() => {
    const { t } = useTranslation();
    const router = useRouter();
    const { authStore } = useRootStore();

    const user = authStore.userProfile;
    const fullName = user?.fullName?.toString();

    if (user) {
        const UserMenu = () => {
            return (
                <DropdownMenu>
                    <>
                        <DropdownMenuItem
                            url={routes.app.profile}
                            name={fullName}
                            icon={<IconUser />}
                            selected={router.pathname === routes.app.profile}
                        />
                        {user?.role === 'admin' ? (
                            <>
                                <DropdownMenuItem
                                    url={routes.app.admin.statistics}
                                    name={t('layout.navigation.administration')}
                                    icon={<IconSettings />}
                                    selected={router.pathname === routes.app.admin.users}
                                />
                                <DropdownMenuItem
                                    onClick={() => authStore.impersonationDialogStore.setImpersonateActive()}
                                    name={t('dialogs.common.impersonation')}
                                    icon={<IconImpersonate />}
                                />
                            </>
                        ) : null}
                        <DropdownMenuItem
                            name={t('layout.profile.logOut')}
                            icon={<IconLogout />}
                            onClick={async () => {
                                await router.push(routes.web.indexLogout);
                            }}
                        />
                    </>
                </DropdownMenu>
            );
        };

        const initials = fullName
            ?.split(' ')
            .map((n) => n[0])
            .join('');

        return (
            <Dropdown content={<UserMenu />}>
                <Avatar variant="primary" size={{ xs: 'xsmall', xl: 'medium' }} initials={initials} />
            </Dropdown>
        );
    }

    // TODO: tohle se zobrazuje kdy?!
    return (
        <IconButton
            size={{ xs: 'xsmall', xxl: 'medium' }}
            icon={<IconUser />}
            onClick={() => authStore.authDialogStore.setLoginActive()}
        />
    );
});
