import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { IconButton } from '../../../../../components/app/icon/button/icon-button';
import { IconInvisible } from '../../../../../components/app/icons/invisible';
import { IconVisible } from '../../../../../components/app/icons/visible';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { Tooltip } from '../../../../../components/app/tooltip/tooltip';

export const HeaderButtonPrivate: React.FC = observer(() => {
    const { t } = useTranslation();

    const { privateStore } = useRootStore();

    const handlePrivateModeClick = () => {
        privateStore.togglePrivateModeEnabled();
    };

    return (
        <Tooltip
            content={t('pages.app.header.button.private')}
            position={'bottom-left'}
            size={'small'}
            arrowPosition={1.6}
        >
            <IconButton
                size={{ xs: 'xsmall', xl: 'medium' }}
                icon={!privateStore.isPrivateModeEnabled ? <IconInvisible /> : <IconVisible />}
                onClick={handlePrivateModeClick}
            />
        </Tooltip>
    );
});
