import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconVisible: React.FC = () => (
    <Icon viewBox="0 0 16 10" fill="none">
        <path
            d="M8 3C8.57865 3 9.13361 3.21071 9.54278 3.58579C9.95195 3.96086 10.1818 4.46957 10.1818 5C10.1818 5.53043 9.95195 6.03914 9.54278 6.41421C9.13361 6.78929 8.57865 7 8 7C7.42135 7 6.86639 6.78929 6.45722 6.41421C6.04805 6.03914 5.81818 5.53043 5.81818 5C5.81818 4.46957 6.04805 3.96086 6.45722 3.58579C6.86639 3.21071 7.42135 3 8 3ZM8 0C11.6364 0 14.7418 2.07333 16 5C14.7418 7.92667 11.6364 10 8 10C4.36364 10 1.25818 7.92667 0 5C1.25818 2.07333 4.36364 0 8 0ZM1.58545 5C2.17328 6.10021 3.08604 7.02717 4.21998 7.6755C5.35393 8.32384 6.66356 8.66755 8 8.66755C9.33644 8.66755 10.6461 8.32384 11.78 7.6755C12.914 7.02717 13.8267 6.10021 14.4145 5C13.8267 3.8998 12.914 2.97283 11.78 2.3245C10.6461 1.67616 9.33644 1.33245 8 1.33245C6.66356 1.33245 5.35393 1.67616 4.21998 2.3245C3.08604 2.97283 2.17328 3.8998 1.58545 5Z"
            fill="currentColor"
        />
    </Icon>
);
