import styled, { css } from 'styled-components';

export const ActionsWrapper = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        gap: 1.2rem;
    `}
`;

export const Wrapper = styled.div`
    ${({ theme }) => css`
        @media ${theme.breakpoints.lg.max} {
            td {
                padding: ${theme.gap.vsmall} 0;
            }

            th {
                padding-top: ${theme.gap.medium};
                padding-bottom: ${theme.gap.small};
                padding-left: 0;
                padding-right: 0;
            }
        }
    `}
`;

export const HeaderText = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.fontSize};

        @media ${theme.breakpoints.lg.max} {
            font-size: 1rem;
            line-height: 1rem;
        }
    `}
`;

export const BodyText = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.medium.fontSize};
        font-weight: 700;

        @media ${theme.breakpoints.lg.max} {
            font-size: ${theme.text.size.vsmall.fontSize};
            line-height: ${theme.text.size.vsmall.fontSize};
        }
    `}
`;
