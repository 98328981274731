import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../tooltip/tooltip';
import { Wrapper } from './floating-buttons-discord.styled';
import { IconDiscord } from '../../icons/discord';

export const FloatingButtonsDiscord: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Tooltip content={t('layout.floatingButtons.discord.tooltip')} position="top-right" arrowPosition={1.4}>
            <Wrapper href={'https://discord.gg/n4MYgUh9KF'} target={'_blank'}>
                <IconDiscord />
            </Wrapper>
        </Tooltip>
    );
};
