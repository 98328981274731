import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../../../../components/app/icon/button/icon-button';
import { IconStarFilled } from '../../../../../components/app/icons/star-filled';
import { routes } from '../../../../../config/routes';
import { Tooltip } from '../../../../../components/app/tooltip/tooltip';

export const HeaderButtonWatchlist: React.FC = () => {
    const { t } = useTranslation();
    const router = useRouter();

    return (
        <Tooltip
            content={t('pages.app.header.button.watchlist')}
            position={'bottom-right'}
            size={'small'}
            arrowPosition={1.6}
        >
            <IconButton
                size={{ xs: 'xsmall', xl: 'medium' }}
                icon={<IconStarFilled />}
                variant={router.asPath === routes.app.watchlist ? 'secondary' : 'default'}
                onClick={() => router.push(routes.app.watchlist)}
            />
        </Tooltip>
    );
};
