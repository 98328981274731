import styled, { css } from 'styled-components';

export const Wrapper = styled.a`
    ${({ theme }) => css`
        width: 4.8rem;
        height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
        transition: border ${theme.base.transition}, background ${theme.base.transition};
        cursor: pointer;

        @media ${theme.breakpoints.vl.min} {
            border-radius: 10rem 10rem 0 0;
            border-bottom: 0.1rem solid ${theme.palette.common.border};
        }

        i {
            color: ${theme.palette.color.secondary.main};
            width: 1.8rem;
            height: 1.8rem;
            transition: color ${theme.base.transition};
        }

        &:hover {
            background-color: ${theme.palette.color.secondary.main};

            i {
                color: ${theme.palette.common.textInverse};
            }
        }
    `}
`;
