import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useRootStore } from '../../../../providers/root-store-provider';
import { DropdownMenu } from '../../dropdown/menu/dropdown-menu';
import { DropdownMenuItem } from '../../dropdown/menu/item/dropdown-menu-item';
import { routes } from '../../../../config/routes';
import { IconSettings } from '../../icons/settings';
import { PortfoliosListItemSimpleRegular } from '../../../../blocks/app/portfolios/list/item/simple/regular/portfolios-list-item-simple-regular';
import { PortfoliosListItemSimpleSummary } from '../../../../blocks/app/portfolios/list/item/simple/summary/portfolios-list-item-simple-summary';
import { Wrapper } from './portfolio-menu.styled';
import { getPortfolios } from '../../../../utils/get-portfolios';

export const PortfolioMenu: React.FC = observer(() => {
    const { t } = useTranslation();
    const { portfolioStore, statisticStore, authStore } = useRootStore();

    const router = useRouter();
    const isIndex = router.pathname === routes.app.index;

    const { summary, filteredPortfolios } = getPortfolios();

    const canShowSummary = portfolioStore.portfolios.length > 2;

    return (
        <DropdownMenu>
            <>
                <Wrapper>
                    {filteredPortfolios.map((row, index) => (
                        <PortfoliosListItemSimpleRegular
                            key={row.id}
                            data={row}
                            disabled={authStore.userHasFreeTariff && index !== portfolioStore.portfolios.length - 1}
                            onClick={async () => {
                                await portfolioStore.selectPortfolio(row.id);
                                if (isIndex) {
                                    await statisticStore.profitStore.fetchProfitCharts();
                                }
                            }}
                        />
                    ))}

                    {summary && canShowSummary ? (
                        <PortfoliosListItemSimpleSummary
                            data={summary}
                            disabled={authStore.userHasFreeTariff}
                            onClick={async () => {
                                await portfolioStore.selectPortfolio(summary.id);
                                if (isIndex) {
                                    await statisticStore.profitStore.fetchProfitCharts();
                                }
                            }}
                        />
                    ) : null}
                </Wrapper>

                <DropdownMenuItem
                    url={routes.app.portfolios}
                    icon={<IconSettings />}
                    name={t('layout.portfolioMenu.portfolioManagement')}
                />
            </>
        </DropdownMenu>
    );
});
