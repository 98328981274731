import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../../../../components/app/icon/button/icon-button';
import { Tooltip } from '../../../../../components/app/tooltip/tooltip';
import { IconSearch } from '../../../../../components/app/icons/search';
import { useRootStore } from '../../../../../providers/root-store-provider';

export const HeaderButtonSearch: React.FC = () => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const { symbolsStore } = rootStore;

    return (
        <Tooltip
            content={t('pages.app.header.button.search')}
            position={'bottom-right'}
            size={'small'}
            arrowPosition={1.6}
        >
            <IconButton
                size={{ xs: 'xsmall', xl: 'medium' }}
                icon={<IconSearch />}
                onClick={() => {
                    symbolsStore.fetchSymbols();
                    symbolsStore.symbolDialogStore.openSearchDialog();
                }}
            />
        </Tooltip>
    );
};
