import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../form/button/button';

type OffBoardingStep1ActionProps = {
    onContinue: () => void;
    onCancel: () => void;
};

export const OffBoardingStep1Action: React.FC<OffBoardingStep1ActionProps> = observer(
    ({ onContinue, onCancel }: OffBoardingStep1ActionProps) => {
        const { t } = useTranslation();

        return (
            <>
                <Button variant="negative" variation="outlined" onClick={onContinue}>
                    {t('dialogs.offboarding.step1.button.continue')}
                </Button>
                <Button variant="positive" onClick={onCancel}>
                    {t('dialogs.offboarding.step1.button.cancel')}
                </Button>
            </>
        );
    },
);
