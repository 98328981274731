import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        padding: ${theme.gap.xsmall} ${theme.gap.xsmall};
    `,
);

export const List = styled.li`
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    padding-bottom: 1rem;
`;
