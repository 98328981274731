import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        border-radius: 2.4rem;
        width: 100%;
        color: var(--tariff-color);
        background-color: transparent;
        padding: 3rem;
        border: 0.1rem solid ${theme.palette.common.border};
    `,
);
