import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconLightMode: React.FC = () => (
    <Icon viewBox="0 0 10 10" fill="none">
        <path
            d="M5.625 0h-1.25v1.25h1.25V0ZM8.934 1.907l-.884-.884-.884.884.884.884.884-.884ZM10 4.375H8.75v1.25H10v-1.25ZM7.21 8.137l.884.884.883-.885-.884-.883-.883.884ZM5.625 8.75h-1.25V10h1.25V8.75ZM.979 8.093l.883.884.885-.884-.884-.884-.884.884ZM1.25 4.375H0v1.25h1.25v-1.25ZM2.79 1.95l-.884-.883-.883.884.884.884.883-.885ZM5 2.5A2.507 2.507 0 0 0 2.5 5c0 1.375 1.125 2.5 2.5 2.5S7.5 6.375 7.5 5 6.375 2.5 5 2.5Z"
            fill="currentColor"
        />
    </Icon>
);
