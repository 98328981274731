import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ModalAlignEnum } from '@app/common';
import { useRootStore } from '../../../../providers/root-store-provider';
import { BaseDialog } from '../base-dialog';
import { OffBoardingStep1 } from './step1/offboarding-step1';
import { OffBoardingStep1Action } from './step1/action/offboarding-step1-action';
import { OffBoardingStep2 } from './step2/offboarding-step2';
import { OffBoardingStep2Action } from './step2/action/offboarding-step2-action';
import { OffBoardingStep3 } from './step3/offboarding-step3';
import { OffBoardingStep3Action } from './step3/action/offboarding-step3-action';
import { OffBoardingStep4Action } from './step4/action/offboarding-step4-action';
import { OffBoardingStep5 } from './step5/offboarding-step5';
import { OffBoardingStep5Action } from './step5/action/offboarding-step5-action';
import { StornoStepEnum } from '../../../../models/enums/storno-step-enum';

export const OffBoardingDialog: React.FC = observer(() => {
    const { t } = useTranslation();
    const { offboardingStore } = useRootStore();

    const getAction = () => {
        switch (offboardingStore.step) {
            case StornoStepEnum.Reason: {
                return (
                    <OffBoardingStep2Action
                        onContinue={async () => {
                            // TODO: change after implementation of stripeStore.setStornoDiscount();
                            const hadStornoDiscount = true;
                            // const hadStornoDiscount = await userStore.hadStornoDiscount();

                            if (hadStornoDiscount) {
                                offboardingStore.setStep(StornoStepEnum.AlreadyHaveDiscount);
                            } else {
                                offboardingStore.setStep(StornoStepEnum.Discount);
                            }
                        }}
                        onCancel={() => offboardingStore.close()}
                    />
                );
            }
            case StornoStepEnum.Discount: {
                return (
                    <OffBoardingStep3Action
                        onContinue={async () => {
                            if (offboardingStore.active === 'cancelPremium') {
                                await offboardingStore.completeOffboarding();
                            } else {
                                offboardingStore.setStep(StornoStepEnum.InformationAboutDeletion);
                            }
                        }}
                        onCancel={() => offboardingStore.getDiscount()}
                    />
                );
            }
            case StornoStepEnum.AlreadyHaveDiscount: {
                return (
                    <OffBoardingStep4Action
                        onContinue={async () => {
                            if (offboardingStore.active === 'cancelPremium') {
                                await offboardingStore.completeOffboarding();
                            } else {
                                offboardingStore.setStep(StornoStepEnum.InformationAboutDeletion);
                            }
                        }}
                        onClose={() => offboardingStore.close()}
                    />
                );
            }
            case StornoStepEnum.InformationAboutDeletion: {
                return (
                    <OffBoardingStep5Action
                        onContinue={() => offboardingStore.completeOffboarding()}
                        onClose={() => offboardingStore.close()}
                    />
                );
            }
            default: {
                return (
                    <OffBoardingStep1Action
                        onContinue={() => offboardingStore.setStep(StornoStepEnum.Reason)}
                        onCancel={() => offboardingStore.close()}
                    />
                );
            }
        }
    };

    const getContent = () => {
        switch (offboardingStore.step) {
            case StornoStepEnum.Reason: {
                return <OffBoardingStep2 />;
            }
            case StornoStepEnum.Discount: {
                return <OffBoardingStep3 />;
            }
            case StornoStepEnum.AlreadyHaveDiscount: {
                return <></>;
            }
            case StornoStepEnum.InformationAboutDeletion: {
                return <OffBoardingStep5 />;
            }
            default: {
                return <OffBoardingStep1 />;
            }
        }
    };

    return (
        <BaseDialog
            opened={!!offboardingStore.active}
            close={() => offboardingStore.close()}
            locked={offboardingStore.step === StornoStepEnum.Discount}
            action={getAction()}
            title={t(`dialogs.offboarding.step${offboardingStore.step}.title`)}
            content={getContent()}
            width={46}
            align={
                offboardingStore.step === StornoStepEnum.AlreadyHaveDiscount
                    ? ModalAlignEnum.Center
                    : ModalAlignEnum.Left
            }
        />
    );
});
