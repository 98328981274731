import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        position: relative;
        cursor: pointer;
        width: 3rem;
        height: 3rem;
        align-items: center;
        justify-content: center;
        background: ${theme.palette.color.secondary.main};
        border-radius: 100%;

        @media ${theme.breakpoints.vl.min} {
            display: none;
        }
    `,
);

export const Inner = styled.div`
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 1.6rem;
    height: 0.8rem;
`;

export const Line = styled.div(
    ({ theme }) => css`
        position: absolute;
        display: block;
        width: 1.6rem;
        height: 0.2rem;
        background-color: ${theme.palette.common.textInverse};
        transition: all ${theme.base.transition};
    `,
);

export const LineUp = styled(Line).withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
    isActive: boolean;
}>(
    ({ isActive }) => css`
        top: 0;

        ${isActive &&
        css`
            top: 0.3rem;
            left: 0;
            width: 1.6rem;
            transform: rotate(45deg);
        `}
    `,
);

export const LineDown = styled(Line).withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
    isActive: boolean;
}>(
    ({ isActive }) => css`
        bottom: 0;

        ${isActive &&
        css`
            bottom: 0.3rem;
            left: 0;
            width: 1.6rem;
            transform: rotate(-45deg);
        `}
    `,
);
