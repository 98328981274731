import styled, { css } from 'styled-components';
import { PaletteColorType } from '../../../theme/type/palette-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant', 'textVariant'].includes(prop),
})<{
    variant: PaletteColorType;
    textVariant: PaletteColorType;
}>(
    ({ theme, variant, textVariant }) => css`
        background: ${theme.palette.color[variant].lighten};
        padding: 0 ${theme.gap.vsmall};
        height: ${theme.base.barHeight};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.palette.color[textVariant].main};
        font-weight: 700;
        z-index: 902;
        font-size: ${theme.text.size.small.fontSize};
        line-height: ${theme.text.size.small.lineHeight};
        box-shadow: inset 0 -0.1rem 0.4rem ${theme.palette.color[variant].main};
        position: sticky;
        top: 0;
    `,
);

export const Inner = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    gap: 2rem;
`;
