import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../../providers/root-store-provider';
import { Button } from '../../form/button/button';
import { Action, Description, Title } from './get-premium-dialog.styled';
import { BasePremiumDialog } from '../base-premium-dialog';
import { LookupKeyEnum } from '../../../../defs/api';

export const GetPremiumDialog: React.FC = observer(() => {
    const { t } = useTranslation();
    const { premiumDialogStore, stripeStore, authStore } = useRootStore();
    const [isLoading, setIsLoading] = React.useState(false);

    const lookupKey =
        premiumDialogStore.period === 'yearly'
            ? LookupKeyEnum.PremiumYearlyCzk202411
            : LookupKeyEnum.PremiumMonthlyCzk202411;

    return (
        <>
            <BasePremiumDialog
                opened={premiumDialogStore.opened}
                close={() => premiumDialogStore.close()}
                locked
                content={
                    <>
                        <Title>{t(`layout.subscription.event.${premiumDialogStore.type}.title`)}</Title>
                        <Description>
                            {t(`layout.subscription.event.${premiumDialogStore.type}.description`)}
                        </Description>
                        <Action>
                            <Button
                                onClick={async () => {
                                    setIsLoading(true);
                                    await stripeStore.createCheckoutSession(lookupKey);
                                    await authStore.fetchUserProfile();
                                    setIsLoading(false);
                                }}
                                variant="positive"
                                shadow
                                loading={isLoading}
                                block
                                size="vlarge"
                                form={'create-portfolio-form'}
                                type={'submit'}
                            >
                                {t('layout.subscription.wantPremium')}
                            </Button>
                        </Action>
                    </>
                }
            />
        </>
    );
});
