import Link from 'next/link';
import React from 'react';
import { formatDecimal } from '@app/common';
import { IconWrapper, Inner, Label, Value, Wrapper } from './dropdown-menu-item.styled';

type DropdownMenuItemType = {
    name?: string;
    url?: string;
    icon?: React.ReactNode;
    value?: number;
    selected?: boolean;
    onClick?: () => Promise<void> | void;
    currencyCode?: string;
    size?: 'small' | 'medium';
};

export const DropdownMenuItem: React.FC<DropdownMenuItemType> = ({
    name,
    url,
    onClick,
    selected = false,
    icon,
    value,
    currencyCode,
    size = 'medium',
}: DropdownMenuItemType) => {
    const DropdownMenuItemStyled = () => (
        <Inner>
            <IconWrapper size={size} selected={selected}>
                {icon}
            </IconWrapper>
            <Label selected={selected}>{name}</Label>
            {value !== undefined && <Value>{`${formatDecimal(value)} ${currencyCode}`}</Value>}
        </Inner>
    );

    if (!url) {
        return (
            <Wrapper size={size} selected={selected} onClick={onClick}>
                <DropdownMenuItemStyled />
            </Wrapper>
        );
    }

    return (
        <Wrapper size={size} selected={selected}>
            <Link href={url} passHref>
                <a>
                    <DropdownMenuItemStyled />
                </a>
            </Link>
        </Wrapper>
    );
};
