import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        background-color: ${theme.palette.background.header};
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.1rem solid ${theme.palette.common.border};
        padding: ${theme.gap.vsmall} ${theme.gap.small};

        @media ${theme.breakpoints.md.min} {
            padding: ${theme.gap.vsmall} ${theme.gap.medium};
        }
    `,
);

export const LogoPortfolioWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.gap.xsmall};
    `,
);

export const VerticalDivider = styled.div(
    ({ theme }) => css`
        position: relative;
        height: auto;
        align-self: stretch;
        width: 0.1rem;
        background: ${theme.palette.common.border};
    `,
);
