import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../../../../web/tariff/premium/tariff-premium.styled';
import { TariffWrapper } from '../../../../web/tariff/wrapper/tariff-wrapper';
import { TariffHeader } from '../../../../web/tariff/header/tariff-header';
import { TariffPremiumPrice } from '../../../../web/tariff/premium/price/tariff-premium-price';
import { TariffFeaturesPremium } from '../../../../web/tariff/features/premium/tariff-features-premium';
import { PricingSwitchPayment } from '../../../../../blocks/web/pricing/switch-payment/pricing-switch-payment';
import { PricingPaymentTypeWrapper } from './modal-premium-tariff.styled';
import { TariffBadge } from '../../../../web/tariff/badge/tariff-badge';
import { useRootStore } from '../../../../../providers/root-store-provider';

export const ModalPremiumTariff: React.FC = observer(() => {
    const { premiumDialogStore } = useRootStore();
    const { t } = useTranslation();

    return (
        <Wrapper>
            <TariffWrapper>
                <PricingPaymentTypeWrapper>
                    <PricingSwitchPayment
                        period={premiumDialogStore.period}
                        onPeriodChange={(p) => premiumDialogStore.setPeriod(p)}
                        discountPosition="top"
                        inverse
                    />
                </PricingPaymentTypeWrapper>

                <TariffHeader
                    name={t(`blocks.pricing.premium.name`)}
                    badge={
                        premiumDialogStore.period === 'yearly' ? (
                            <TariffBadge content={t(`blocks.pricing.premium.twoMonthsFree`)} />
                        ) : null
                    }
                />

                <TariffPremiumPrice period={premiumDialogStore.period} />

                <TariffFeaturesPremium />
            </TariffWrapper>
        </Wrapper>
    );
});
