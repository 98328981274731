import * as React from 'react';

import { Wrapper } from './table-action.styled';
import { IconButton } from '../../icon/button/icon-button';
import {
    IconButtonSizeType,
    IconButtonVariantType,
    IconButtonVariationType,
} from '../../../../theme/type/icon-button-type';

export interface TableActionPropsInterface {
    icon: React.ReactNode;
    variant?: IconButtonVariantType;
    variation?: IconButtonVariationType;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
    size?: IconButtonSizeType | object;
}

export const TableAction: React.FC<TableActionPropsInterface> = ({
    icon,
    onClick,
    variant = 'default',
    variation = 'filled',
    size = 'small',
    disabled,
    loading,
}) => {
    return (
        <Wrapper>
            <IconButton
                onClick={onClick}
                variant={variant}
                variation={variation}
                loading={loading}
                icon={icon}
                size={size}
                disabled={disabled}
            />
        </Wrapper>
    );
};
