import { useMemo } from 'react';
import { PortfolioTypeEnum } from '../defs/api';
import { useRootStore } from '../providers/root-store-provider';

export function getPortfolios() {
    const rootStore = useRootStore();
    const { portfolioStore } = rootStore;

    return useMemo(() => {
        const summary = portfolioStore.portfolios.find((p) => p.type === PortfolioTypeEnum.Summary);
        const filteredPortfolios = portfolioStore.portfolios.filter((p) => p.type !== PortfolioTypeEnum.Summary);
        return { summary, filteredPortfolios };
    }, [portfolioStore.portfolios]);
}
