import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconSearch: React.FC = () => (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_3250_6886)">
            <path
                d="M15.25 15.25L11.5798 11.5798M11.5798 11.5798C12.1689 10.9907 12.6361 10.2913 12.955 9.52165C13.2738 8.75197 13.4379 7.92703 13.4379 7.09393C13.4379 6.26084 13.2738 5.4359 12.955 4.66621C12.6361 3.89653 12.1689 3.19718 11.5798 2.60809C10.9907 2.01901 10.2913 1.55172 9.52165 1.2329C8.75197 0.91409 7.92703 0.75 7.09393 0.75C6.26084 0.75 5.4359 0.91409 4.66621 1.2329C3.89653 1.55172 3.19718 2.01901 2.60809 2.60809C1.41838 3.79781 0.75 5.41142 0.75 7.09393C0.75 8.77645 1.41838 10.3901 2.60809 11.5798C3.79781 12.7695 5.41142 13.4379 7.09393 13.4379C8.77645 13.4379 10.3901 12.7695 11.5798 11.5798Z"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_3250_6886">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </Icon>
);
