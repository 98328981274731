import React from 'react';
import { Wrapper } from './floating-buttons.styled';
import { FloatingButtonsDiscord } from './discord/floating-buttons-discord';
import { FloatingButtonsGuide } from './guide/floating-buttons-guide';

export const FloatingButtons: React.FC = () => {
    return (
        <Wrapper>
            <FloatingButtonsGuide />
            <FloatingButtonsDiscord />
        </Wrapper>
    );
};
