import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { Table } from '../../../table/table';
import { TableHead } from '../../../table/head/table-head';
import { TableRow } from '../../../table/row/table-row';
import { TableCell } from '../../../table/cell/table-cell';
import { TableBody } from '../../../table/body/table-body';
import { Text } from '../../../text/text';
import { NoData } from '../../../no-data/no-data';
import { ActionsWrapper, BodyText, HeaderText, Wrapper } from './search-symbol-dialog-list.styled';
import { Symbol } from '../../../symbol/symbol';
import { routes } from '../../../../../config/routes';
import { TableActionGoToSymbolDetail } from '../../../table/action/go-to-symbol-detail/table-action-go-to-symbol-detail';
import { SymbolMobxDto } from '../../../../../models/mobx/dtos/symbol/symbol-mobx-dto';

export const SearchSymbolDialogList: React.FC = observer(() => {
    const { t } = useTranslation();
    const router = useRouter();
    const { symbolsStore } = useRootStore();

    return (
        <Wrapper>
            {symbolsStore.hasSymbols || symbolsStore.loading ? (
                <Table loading={symbolsStore.loading}>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'}>
                                <HeaderText>{t('layout.tables.watchlist.asset')}</HeaderText>
                            </TableCell>
                            <TableCell align={'left'}>
                                <HeaderText>{t('layout.tables.watchlist.exchange')}</HeaderText>
                            </TableCell>
                            {/* <TableCell align="left"> */}
                            {/*    <HeaderText>{t('layout.tables.watchlist.marketValue')}</HeaderText> */}
                            {/* </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {symbolsStore.symbols.map((row: SymbolMobxDto) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell align={'left'}>
                                        <Symbol
                                            url={`${routes.app.symbols}/${row.exchangeId}/${row.symbol}`}
                                            onClick={() => symbolsStore.symbolDialogStore.closeSearchDialog()}
                                            size={'small'}
                                            type={row.type}
                                            symbol={row.symbol}
                                            name={row.name}
                                            description={row.symbol}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <Text tag={'span'} bold>
                                            <BodyText>{row.exchangeId}</BodyText>
                                        </Text>
                                    </TableCell>
                                    {/* <TableCell align="left"> */}
                                    {/*    <Text tag={'span'} bold> */}
                                    {/*        <BodyText> */}
                                    {/*            {row.marketCapitalizationBn */}
                                    {/*                ? `${formatDecimal(row.marketCapitalizationBn, 2)} bn` */}
                                    {/*                : '-'} */}
                                    {/*        </BodyText> */}
                                    {/*    </Text> */}
                                    {/* </TableCell> */}
                                    <TableCell align="right">
                                        <ActionsWrapper>
                                            <TableActionGoToSymbolDetail
                                                onClick={async () => {
                                                    await router.push(
                                                        routes.app.symbols.concat(
                                                            '/',
                                                            row.exchangeId || '',
                                                            '/',
                                                            row.symbol,
                                                        ),
                                                    );
                                                    symbolsStore.symbolDialogStore.closeSearchDialog();
                                                }}
                                            />
                                        </ActionsWrapper>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <NoData fullWidth>{t('layout.tables.noData')}</NoData>
            )}
        </Wrapper>
    );
});
