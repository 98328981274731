import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconStarFilled: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            d="M23.56,8.73a1.51,1.51,0,0,0-1.41-1H16.06a.5.5,0,0,1-.47-.33l-2.18-6.2A1.52,1.52,0,0,0,12,.25a1.49,1.49,0,0,0-1.4,1v0L8.41,7.42a.5.5,0,0,1-.47.33H1.85a1.5,1.5,0,0,0-1.41,1A1.52,1.52,0,0,0,.89,10.4l5.18,4.3a.5.5,0,0,1,.16.54L4.05,21.77a1.5,1.5,0,0,0,2.31,1.69l5.34-3.92a.49.49,0,0,1,.59,0l5.35,3.92A1.5,1.5,0,0,0,20,21.77l-2.18-6.53a.5.5,0,0,1,.16-.54l5.19-4.31A1.51,1.51,0,0,0,23.56,8.73Z"
            fill="currentColor"
        />
    </Icon>
);
