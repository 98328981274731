declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zE: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zESettings: any;
    }
}

export const loadZendesk = (zendeskToken: string) => {
    const scriptId = 'ze-snippet';
    if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=f7317d16-29b1-4a85-b68d-0983bf69867d';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.zE('messenger', 'loginUser', (callback: (token: string) => void) => {
                callback(zendeskToken);
            });
        };
    }
};
