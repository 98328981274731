import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InfoBox } from '../info-box';
import { Item } from '../info-box.styled';
import { IconNext } from '../../icons/next';
import { Text } from '../../text/text';

export const InfoBoxUnrealizedProfit: React.FC = () => {
    const { t } = useTranslation();

    return (
        <InfoBox
            image={'/images/info-box/unrealized-profit.png'}
            name={t('infoBox.unrealizedProfit.name')}
            more={t('infoBox.unrealizedProfit.more')}
            description={<Trans i18nKey="infoBox.unrealizedProfit.description" components={{ strong: <strong /> }} />}
            extraContent={
                <>
                    <Item>
                        <IconNext />
                        <Text>
                            <Trans i18nKey="infoBox.portfolioValue.extra.01" components={{ strong: <strong /> }} />
                        </Text>
                    </Item>
                    <Item>
                        <IconNext />
                        <Text>
                            <Trans i18nKey="infoBox.portfolioValue.extra.02" components={{ strong: <strong /> }} />
                        </Text>
                    </Item>
                    <Item>
                        <IconNext />
                        <Text>
                            <Trans i18nKey="infoBox.portfolioValue.extra.03" components={{ strong: <strong /> }} />
                        </Text>
                    </Item>
                    <Item>
                        <IconNext />
                        <Text>
                            <Trans i18nKey="infoBox.portfolioValue.extra.04" components={{ strong: <strong /> }} />
                        </Text>
                    </Item>
                    <Item>
                        <IconNext />
                        <Text>
                            <Trans i18nKey="infoBox.portfolioValue.extra.05" components={{ strong: <strong /> }} />
                        </Text>
                    </Item>
                </>
            }
        />
    );
};
