import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/root-store-provider';
import { routes } from '../../../config/routes';
import { IconDashboard } from '../../../components/app/icons/dashboard';
import { IconTrades } from '../../../components/app/icons/trades';
import { IconInvestment } from '../../../components/app/icons/investment';
import { IconProfits } from '../../../components/app/icons/profits';
import { IconDividends } from '../../../components/app/icons/dividends';
import { IconReport } from '../../../components/app/icons/report';
import { IconLogout } from '../../../components/app/icons/logout';
import { IconUser } from '../../../components/app/icons/user';
import { IconSettings } from '../../../components/app/icons/settings';
import { IconImpersonate } from '../../../components/app/icons/impersonate';
import { Menu } from '../../../components/app/menu/menu';
import { MenuItem } from '../../../components/app/menu/item/item';
import { Responsive } from '../../../components/app/responsive/responsive';
import { Divider } from '../../../components/app/divider/divider';

export const Navigation: React.FC = observer(() => {
    const { themeStore, authStore } = useRootStore();
    const router = useRouter();
    const { t } = useTranslation();

    const user = authStore.userProfile;
    const fullName = user?.fullName?.toString();

    const pages = [
        {
            icon: <IconDashboard />,
            name: t('layout.navigation.performance'),
            url: routes.app.index,
            isActive: router.pathname === '/app',
        },
        {
            icon: <IconTrades />,
            name: t('layout.navigation.trades'),
            url: routes.app.trades,
            isActive: router.pathname.includes('/trades'),
        },
        {
            icon: <IconInvestment />,
            name: t('layout.navigation.investment'),
            url: routes.app.invested,
            isActive: router.pathname.includes('/invested'),
        },
        {
            icon: <IconProfits />,
            name: t('layout.navigation.profits'),
            url: routes.app.profits,
            isActive: router.pathname.includes('/profits'),
        },
        {
            icon: <IconDividends />,
            name: t('layout.navigation.dividends'),
            url: routes.app.dividends,
            isActive: router.pathname.includes('/dividends'),
            badge: authStore.userHasFreeTariff ? 'Premium' : null,
        },
        {
            icon: <IconReport />,
            name: t('layout.navigation.taxReport'),
            url: routes.app.taxReport,
            isActive: router.pathname.includes('/tax-report'),
            badge: authStore.userHasFreeTariff ? 'Premium' : null,
        },
    ];

    return (
        <>
            <Menu layout={themeStore.layout}>
                {pages.map((item, index) => (
                    <MenuItem
                        key={index}
                        name={item.name}
                        url={item.url}
                        icon={item.icon}
                        isActive={item.isActive}
                        badge={item.badge}
                    />
                ))}
            </Menu>
            <Responsive breakpoint="vl" type="max">
                <>
                    <Divider gap="vsmall" />
                    <Menu layout={themeStore.layout}>
                        <MenuItem name={fullName as string} url={routes.app.profile} icon={<IconUser />} />
                        {user?.role === 'admin' ? (
                            <>
                                <MenuItem
                                    url={routes.app.admin.statistics}
                                    name={t('layout.navigation.administration')}
                                    icon={<IconSettings />}
                                />
                                <MenuItem
                                    onClick={() => authStore.impersonationDialogStore.setImpersonateActive()}
                                    name={t('dialogs.common.impersonation')}
                                    icon={<IconImpersonate />}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        <MenuItem
                            name={t('layout.profile.logOut')}
                            onClick={async () => {
                                await router.push(routes.web.indexLogout);
                            }}
                            icon={<IconLogout />}
                        />
                    </Menu>
                </>
            </Responsive>
        </>
    );
});
