import styled, { DefaultTheme, css } from 'styled-components';
import { AvatarResponsiveSizeType, AvatarSizeType, AvatarVariantType } from '../../../theme/type/avatar-type';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';

const getCssAvatarSize = (sizeKey: AvatarSizeType, theme: DefaultTheme) => `
    font-size: ${theme.text.size[sizeKey].fontSize};
    height: ${theme.avatar.size[sizeKey].wrapper};
    width: ${theme.avatar.size[sizeKey].wrapper};
`;

const getResponsiveAvatarSize = (size: AvatarResponsiveSizeType, theme: DefaultTheme) => {
    return Object.entries(size).map((item, index) => {
        const breakpointKey = item[0] as BreakpointSizeType;
        const iconSizeKey = item[1] as AvatarSizeType;

        if (index === 0) {
            return `
                @media ${theme.breakpoints[breakpointKey].min} {
                    ${getCssAvatarSize(iconSizeKey, theme)}
                }

                @media ${theme.breakpoints[breakpointKey].max} {
                    ${getCssAvatarSize(iconSizeKey, theme)}
                }

            `;
        }

        return `
            @media ${theme.breakpoints[breakpointKey].min} {
                ${getCssAvatarSize(iconSizeKey, theme)}
            }
        `;
    });
};

const getAvatarSize = (size: AvatarSizeType | AvatarResponsiveSizeType, theme: DefaultTheme) => css`
    ${typeof size === 'string' &&
    css`
        ${getCssAvatarSize(size, theme)};
    `}

    ${typeof size === 'object' &&
    css`
        ${getResponsiveAvatarSize(size, theme)};
    `}
`;

export const Wrapper = styled.span.withConfig({
    shouldForwardProp: (prop) => !['variant', 'size', 'shadow'].includes(prop),
})<{
    variant: AvatarVariantType;
    size: AvatarSizeType | AvatarResponsiveSizeType;
    shadow?: boolean;
}>`
    ${({ theme, variant, size, shadow, onClick }) => css`
        align-items: center;
        display: inline-flex;
        border-radius: 100%;
        background: ${theme.palette.color[variant].main};
        align-items: center;
        justify-content: center;
        font-weight: 900;

        &&& img {
            border-radius: 100%;
        }

        ${variant &&
        css`
            background-color: ${theme.avatar.variant[variant].background};
            color: ${theme.avatar.variant[variant].color};
        `}

        ${shadow &&
        css`
            box-shadow: ${theme.shadow.small};
        `}

        img {
            border-radius: inherit;
        }

        ${getAvatarSize(size, theme)};

        ${onClick &&
        css`
            cursor: pointer;
        `}
    `}
`;
