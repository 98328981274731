import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../theme/utils/get-rgb';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['showBar'].includes(prop),
})<{
    showBar: boolean;
}>(
    ({ theme, showBar }) => css`
        display: flex;
        background-color: ${theme.palette.background.header};
        top: 0;
        z-index: 900;
        border-right: 0.1rem solid ${theme.palette.common.border};
        width: 23.2rem;
        flex-direction: column;
        height: 100vh;
        position: fixed;
        left: 0;

        ${showBar &&
        css`
            top: ${theme.base.barHeight};
        `}
    `,
);

export const LogoPortfolioWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.gap.xsmall};
        padding-left: ${theme.gap.small};
        border-bottom: 0.1rem solid ${theme.palette.common.border};

        @media ${theme.breakpoints.md.min} {
            padding-left: ${theme.gap.medium};
            gap: ${theme.gap.vsmall};
        }

        @media ${theme.breakpoints.vl.min} {
            padding-top: 2.7rem;
            padding-bottom: 2.7rem;
        }
    `,
);

export const Controls = styled.div(
    ({ theme }) => css`
        position: absolute;
        top: 0;
        left: 23.2rem;
        width: calc(100vw - 23.2rem);
        padding: ${theme.gap.small};
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${getOpacity(theme.palette.background.body, 0.9)};
        border-bottom: 0.1rem solid ${theme.palette.common.border};
        backdrop-filter: blur(0.5rem);
    `,
);

export const BackButton = styled.div(
    ({ theme }) => css`
        padding: ${theme.gap.vsmall};
    `,
);
