import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.gap.xsmall};

        @media ${theme.breakpoints.md.min} {
            gap: ${theme.gap.vsmall};
        }
    `,
);

export const WrapperMobile = styled.div.withConfig({
    shouldForwardProp: (prop) => !['showOnMobile'].includes(prop),
})<{
    showOnMobile: boolean;
}>(
    ({ theme, showOnMobile }) => css`
        display: ${showOnMobile ? 'block' : 'none'};
        position: absolute;
        top: 100%;
        background: ${theme.palette.background.header};
        left: 0;
        right: 0;
        box-shadow: inset 0px -1px 0px rgba(153, 153, 153, 0.16);
        border-top: 0.1rem solid ${theme.palette.common.border};
        max-height: calc(100vh - 6.2rem);
        overflow: auto;
    `,
);

export const MenuIndicatorWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        height: 3rem;
        gap: ${theme.gap.xsmall};

        @media ${theme.breakpoints.xl.min} {
            height: 4rem;
        }
    `,
);

export const NameLabel = styled.div(
    ({ theme }) => css`
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.medium.lineHeight};
        font-weight: 700;
        color: ${theme.palette.common.text};
        -webkit-line-clamp: 2;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    `,
);
