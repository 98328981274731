import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../tooltip/tooltip';
import { Wrapper } from './floating-buttons-guide.styled';
import { IconGuide } from '../../icons/guide';

export const FloatingButtonsGuide: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Tooltip content={t('layout.floatingButtons.guide.tooltip')} position="top-right" arrowPosition={1.4}>
            <Wrapper href={'https://monery.zendesk.com/hc/cs'} target={'_blank'}>
                <IconGuide />
            </Wrapper>
        </Tooltip>
    );
};
