import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconReport: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <rect
            x="0.75"
            y="2.25"
            width="22.5"
            height="21"
            rx="1.5"
            ry="1.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="5.25"
            y1="0.75"
            x2="5.25"
            y2="3.75"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="18.75"
            y1="0.75"
            x2="18.75"
            y2="3.75"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="12.75"
            y1="18"
            x2="17.25"
            y2="18"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="12.75"
            y1="10.5"
            x2="17.25"
            y2="10.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <polyline
            points="9.9 15.3 6.75 19.5 4.5 17.25"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <polyline
            points="9.9 7.5 6.75 11.7 4.5 9.45"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
    </Icon>
);
