import styled, { css } from 'styled-components';
import { ThemeEnum } from '@app/common';
import { getOpacity } from '../../../../../../../theme/utils/get-rgb';
import { PaletteColorType } from '../../../../../../../theme/type/palette-type';
import { Wrapper as PortfoliosListItemSimpleWrapper } from '../portfolios-list-item-simple.styled';

export const Wrapper = styled(PortfoliosListItemSimpleWrapper).withConfig({
    shouldForwardProp: (prop) => !['isActive', 'variant', 'selectedTheme'].includes(prop),
})<{
    isActive: boolean;
    variant: PaletteColorType;
    selectedTheme: ThemeEnum;
}>`
    ${({ theme, isActive, variant, selectedTheme }) => css`
        color: ${selectedTheme === ThemeEnum.Light
            ? theme.palette.color[variant].dark
            : theme.palette.color[variant].light};
        background: ${getOpacity(theme.palette.color[variant].main, 0.1)};
        box-shadow: inset 0 0.1rem 0.1rem ${getOpacity(theme.palette.color[variant].main, 0.1)};
        margin-top: 2rem;
        cursor: pointer;
        min-width: 22rem;

        &:hover {
            background: ${getOpacity(theme.palette.color[variant].main, 0.2)};
        }

        ${isActive &&
        css`
            background: ${getOpacity(theme.palette.color[variant].main, 0.8)};
            color: ${theme.palette.color.white.main};
            cursor: default;

            &:hover {
                background: ${getOpacity(theme.palette.color[variant].main, 0.8)};
            }
        `}
    `}
`;

export const Badge = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant: PaletteColorType;
}>`
    ${({ theme, variant }) => css`
        position: absolute;
        top: 0;
        right: 2.5rem;
        transform: translateY(-50%);
        padding: 0.4rem 0.8rem;
        background-color: ${theme.palette.color[variant].darken};
        color: ${theme.palette.color.white.main};
        font-size: 1.1rem;
        line-height: 1.1rem;
        text-transform: uppercase;
        box-shadow: ${theme.shadow.large};
        white-space: nowrap;
        border-radius: 20rem;
        font-weight: 700;
    `}
`;
