import styled, { css } from 'styled-components';
import { getOpacity } from '../../../../theme/utils/get-rgb';

export const Wrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        background-color: ${getOpacity(theme.palette.background.header, 0.9)};
        z-index: 900;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.1rem solid ${theme.palette.common.border};
        padding: 0 ${theme.gap.medium};
        backdrop-filter: blur(0.5rem);
    `,
);

export const LogoPortfolioWrapper = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        gap: ${theme.gap.xsmall};

        @media ${theme.breakpoints.md.min} {
            gap: ${theme.gap.vsmall};
        }
    `,
);

export const BackButton = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        padding: ${theme.gap.vsmall} 0;
    `,
);
