import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconDarkMode: React.FC = () => (
    <Icon viewBox="0 0 10 10" fill="none">
        <path
            d="M3.75 0C1.562.563 0 2.563 0 4.938A5.041 5.041 0 0 0 5.063 10C7.438 10 9.375 8.437 10 6.25 6.187 7.313 2.687 3.812 3.75 0Z"
            fill="currentColor"
        />
    </Icon>
);
