import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconInvisible: React.FC = () => (
    <Icon viewBox="0 0 16 12" fill="none">
        <path
            d="M0.727273 0.846667L1.65818 0L13.8182 11.1467L12.8945 12L10.6545 9.94667C9.81818 10.2 8.93091 10.3333 8 10.3333C4.36364 10.3333 1.25818 8.26 0 5.33333C0.501818 4.16 1.30182 3.12667 2.32 2.30667L0.727273 0.846667ZM8 3.33333C8.57865 3.33333 9.13361 3.54405 9.54278 3.91912C9.95195 4.29419 10.1818 4.8029 10.1818 5.33333C10.1822 5.56038 10.1404 5.78582 10.0582 6L7.27273 3.44667C7.50638 3.37132 7.75232 3.333 8 3.33333ZM8 0.333333C11.6364 0.333333 14.7418 2.40667 16 5.33333C15.4064 6.71547 14.3978 7.91514 13.0909 8.79333L12.0582 7.84C13.0639 7.20225 13.8751 6.33937 14.4145 5.33333C13.8266 4.23329 12.9138 3.30651 11.7798 2.65834C10.6459 2.01016 9.33634 1.66659 8 1.66667C7.20727 1.66667 6.42909 1.78667 5.70182 2L4.58182 0.98C5.62909 0.566667 6.78545 0.333333 8 0.333333ZM1.58545 5.33333C2.17341 6.43337 3.08623 7.36015 4.22017 8.00833C5.3541 8.65651 6.66366 9.00008 8 9C8.50182 9 8.99636 8.95333 9.45455 8.86L7.79636 7.33333C7.2903 7.28361 6.81805 7.07658 6.45816 6.74668C6.09827 6.41678 5.87243 5.98389 5.81818 5.52L3.34545 3.24667C2.62545 3.81333 2.02182 4.52 1.58545 5.33333Z"
            fill="currentColor"
        />
    </Icon>
);
