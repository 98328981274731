import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconDividends: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <line
            x1="0.75"
            y1="14.25"
            x2="0.75"
            y2="23.25"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M.75,21.75h16.5a3,3,0,0,0-3-3H10.5a3,3,0,0,0-3-3H.75"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="6.75"
            y1="18.75"
            x2="10.5"
            y2="18.75"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M20.24,18.008A7.475,7.475,0,0,0,18.218,4.949l1.853-3.17a.679.679,0,0,0,0-.687A.707.707,0,0,0,19.46.75H12.04a.707.707,0,0,0-.609.342.679.679,0,0,0,0,.687l1.853,3.17A7.477,7.477,0,0,0,8.25,12"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M13.7,13.844a2.224,2.224,0,0,0,1.858.875c1.139,0,2.063-.693,2.063-1.547s-.924-1.546-2.063-1.546S13.5,10.933,13.5,10.078s.924-1.547,2.062-1.547a2.221,2.221,0,0,1,1.858.875"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="15.562"
            y1="14.719"
            x2="15.562"
            y2="15.75"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="15.562"
            y1="7.5"
            x2="15.562"
            y2="8.531"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
    </Icon>
);
