import { LayoutEnum } from '@app/common';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['layout', 'showBar'].includes(prop),
})<{
    layout: LayoutEnum;
    showBar: boolean;
}>(
    ({ theme, layout, showBar }) => css`
        position: relative;
        z-index: 901;

        ${layout === LayoutEnum.Vertical &&
        css`
            position: sticky;
            top: 0;
        `}

        ${layout === LayoutEnum.Horizontal &&
        css`
            @media ${theme.breakpoints.vl.max} {
                position: sticky;
                top: 0;
            }
        `}

        ${showBar &&
        css`
            position: sticky;
            top: ${theme.base.barHeight};
        `}
    `,
);

export const VerticalDivider = styled.div(
    ({ theme }) => css`
        position: relative;
        height: auto;
        align-self: stretch;
        width: 0.1rem;
        background: ${theme.palette.common.border};
    `,
);
