import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';
import { useRootStore } from '../../../../../../providers/root-store-provider';
import { TextField } from '../../../../form/textfield/textfield';
import { Responsive } from '../../../../responsive/responsive';
import { IconSearch } from '../../../../icons/search';

export const SearchSymbolDialogTextfield: React.FC = observer(() => {
    const { t } = useTranslation();
    const { symbolsStore } = useRootStore();

    const searchTextFieldComponent = useCallback((isMobile: boolean) => {
        return (
            <TextField
                variant={'outlined'}
                placeholder={
                    isMobile
                        ? t('layout.dialogs.watchlist.addSymbol.title')
                        : t('layout.dialogs.watchlist.addSymbol.searchBySymbolName')
                }
                width={'100%'}
                radius={'circle'}
                size={'small'}
                onChange={(e) => {
                    symbolsStore.triggerSearch(e.target.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconSearch />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }, []);

    return (
        <>
            <Responsive breakpoint={'lg'} type={'min'}>
                {searchTextFieldComponent(false)}
            </Responsive>
            <Responsive breakpoint={'lg'} type={'max'}>
                {searchTextFieldComponent(true)}
            </Responsive>
        </>
    );
});
