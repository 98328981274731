import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconWarningFilled: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            d="M23.25,23.23a.75.75,0,0,0,.66-1.1l-11.25-21a.78.78,0,0,0-1.32,0L.09,22.13a.73.73,0,0,0,0,.74.73.73,0,0,0,.64.36ZM12,20.48A1.5,1.5,0,1,1,13.5,19,1.5,1.5,0,0,1,12,20.48ZM12,8.23a1,1,0,0,1,1,1V14.7a1,1,0,0,1-2,0V9.23A1,1,0,0,1,12,8.23Z"
            fill="currentColor"
        />
    </Icon>
);
