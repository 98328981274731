import * as React from 'react';
import { Controls, LogoPortfolioWrapper, Wrapper } from './header-horizontal.styled';
import { Navigation } from '../../navigation/navigation';
import { useRootStore } from '../../../../providers/root-store-provider';
import { routes } from '../../../../config/routes';
import { AccountBox } from '../../account-box/account-box';
import { Logo } from '../../../../components/app/logo/logo';
import { PortfolioSwitcher } from '../../../../components/app/portfolio/switcher/portfolio-switcher';

export const HeaderHorizontal: React.FC = () => {
    const rootStore = useRootStore();
    const { showBar } = rootStore.themeStore;
    return (
        <Wrapper showBar={showBar}>
            <LogoPortfolioWrapper>
                <Logo href={routes.app.index} inApp />
            </LogoPortfolioWrapper>
            <Navigation />
            <Controls>
                <PortfolioSwitcher />
                <AccountBox />
            </Controls>
        </Wrapper>
    );
};
