import * as React from 'react';

import Image from 'next/image';
import { Wrapper } from './avatar.styled';
import { AvatarResponsiveSizeType, AvatarSizeType, AvatarVariantType } from '../../../theme/type/avatar-type';

interface AvatarPropsInterface {
    initials?: string;
    variant?: AvatarVariantType;
    image?: string;
    shadow?: boolean;
    size?: AvatarSizeType | AvatarResponsiveSizeType;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement & HTMLDivElement>) => void;
    onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Avatar: React.FC<AvatarPropsInterface> = ({
    variant = 'default',
    initials,
    image,
    size = 'medium',
    shadow = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    let dimension = 4.0;
    if (size === 'small') {
        dimension = 3.2;
    } else if (size === 'large') {
        dimension = 4.8;
    }

    return (
        <Wrapper
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            size={size}
            shadow={shadow}
            variant={variant}
        >
            {image && initials && (
                <Image src={image} layout="intrinsic" width={dimension * 10} height={dimension * 10} alt={initials} />
            )}
            {!image && initials}
        </Wrapper>
    );
};
