import { SubscriptionEnum } from '@app/common';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { formatDate } from '../../../models/functions/format-date';
import { useRootStore } from '../../../providers/root-store-provider';
import { Responsive } from '../responsive/responsive';
import { Inner, Wrapper } from './bar.styled';
import { Button } from '../form/button/button';
import { Confirm } from '../confirm/confirm';

export const Bar: React.FC = observer(() => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const { premiumDialogStore, stripeStore, authStore } = rootStore;
    const { userProfile } = authStore;
    const [showReactivateDialog, setShowReactivateDialog] = React.useState(false);

    if (userProfile?.subscriptionEndDate) {
        return (
            <Wrapper variant="negative" textVariant="white">
                <Inner>
                    <Responsive breakpoint="md">
                        <>
                            {t('layout.subscription.subscriptionEndsAt')} {formatDate(userProfile.subscriptionEndDate)}.
                        </>
                    </Responsive>
                    <Confirm
                        isActive={showReactivateDialog}
                        isProcessing={stripeStore.loading}
                        onCancel={() => setShowReactivateDialog(false)}
                        onConfirm={async () => {
                            await stripeStore.reactivateSubscription();
                            setShowReactivateDialog(false);
                        }}
                        title={t('pages.profile.confirm.continueInPremium')}
                        cancelTitle={t('pages.profile.confirm.continueInPremiumCancel')}
                        confirmTitle={t('pages.profile.confirm.continueInPremiumConfirm')}
                        positionY="bottom"
                        variant="positive"
                        position="center"
                    >
                        <Button
                            size="xsmall"
                            variant="white"
                            onClick={async () => {
                                setShowReactivateDialog(true);
                            }}
                        >
                            {t('layout.subscription.continueInPremium')}
                        </Button>
                    </Confirm>
                </Inner>
            </Wrapper>
        );
    }

    if (userProfile?.subscription === SubscriptionEnum.Free) {
        return (
            <Wrapper variant="purple" textVariant="black">
                <Inner>
                    <Responsive breakpoint="md">
                        <>{t('layout.subscription.yourFreePlan')}</>
                    </Responsive>
                    <Button
                        size="xsmall"
                        variant="white"
                        onClick={async () => {
                            premiumDialogStore.open();
                        }}
                    >
                        {t('layout.subscription.goToPremium')}
                    </Button>
                </Inner>
            </Wrapper>
        );
    }

    return null;
});
