import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconArrowRightFull: React.FC = () => (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_3788_45386)">
            <path d="M0 8L15 8" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
            <path
                d="M9.3 3L14.9 8L9.3 13"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="square"
            />
        </g>
        <defs>
            <clipPath id="clip0_3788_45386">
                <rect width="16" height="16" fill="currentColor" />
            </clipPath>
        </defs>
    </Icon>
);
