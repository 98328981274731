import React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper, Inner, LineUp, LineDown } from './hamburger-menu.styled';
import { useRootStore } from '../../../providers/root-store-provider';

export const HamburgerMenu: React.FC = observer(() => {
    const { themeStore } = useRootStore();

    return (
        <Wrapper
            onClick={() => {
                themeStore.toggleMobileNavigationDisplay();
                themeStore.hideMobilePortfolioNavigation();
            }}
        >
            <Inner>
                <LineUp isActive={themeStore.mobileNavigationDisplay} />
                <LineDown isActive={themeStore.mobileNavigationDisplay} />
            </Inner>
        </Wrapper>
    );
});
