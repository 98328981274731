import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconRefresh: React.FC = () => (
    <Icon viewBox="0 0 16 16" fill="none">
        <path
            d="M16 7V3L14.9 4.1C13.6 1.6 11 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C10.4 16 12.6 14.9 14 13.2L12.5 11.9C11.4 13.2 9.8 14 8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C10.4 2 12.5 3.5 13.5 5.5L12 7H16Z"
            fill="currentColor"
        />
    </Icon>
);
