import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconGuide: React.FC = () => (
    <Icon viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C3.58177 0 0 3.58177 0 8C0 12.4182 3.58177 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58177 12.4182 0 8 0ZM1.11628 8C1.11628 6.30028 1.73246 4.74419 2.75349 3.54307L5.13712 5.92595C4.69926 6.52876 4.46397 7.25496 4.46512 8C4.46397 8.74504 4.69926 9.47124 5.13712 10.074L2.75349 12.4569C1.69358 11.2141 1.1129 9.63338 1.11628 8ZM5.92595 5.13712L3.54307 2.75349C4.78585 1.69358 6.36662 1.1129 8 1.11628C9.69972 1.11628 11.2558 1.73246 12.4569 2.75349L10.0733 5.13712C9.4707 4.69941 8.74479 4.46413 8 4.46512C7.25496 4.46397 6.52876 4.69926 5.92595 5.13712ZM3.54307 13.2465C4.78585 14.3064 6.36662 14.8871 8 14.8837C9.63338 14.8871 11.2141 14.3064 12.4569 13.2465L10.0733 10.8629C9.4707 11.3006 8.74479 11.5359 8 11.5349C7.25496 11.536 6.52876 11.3007 5.92595 10.8629L3.54307 13.2465ZM10.8629 10.0733L13.2465 12.4569C14.3064 11.2141 14.8871 9.63338 14.8837 8C14.8871 6.36662 14.3064 4.78585 13.2465 3.54307L10.8629 5.92595C11.3007 6.52876 11.536 7.25496 11.5349 8C11.536 8.74504 11.3007 9.47049 10.8629 10.0733ZM5.5814 8C5.5814 7.35855 5.83621 6.74336 6.28979 6.28979C6.74336 5.83621 7.35855 5.5814 8 5.5814C8.64145 5.5814 9.25664 5.83621 9.71021 6.28979C10.1638 6.74336 10.4186 7.35855 10.4186 8C10.4186 8.64145 10.1638 9.25664 9.71021 9.71021C9.25664 10.1638 8.64145 10.4186 8 10.4186C7.35855 10.4186 6.74336 10.1638 6.28979 9.71021C5.83621 9.25664 5.5814 8.64145 5.5814 8Z"
            fill="currentColor"
        />
    </Icon>
);
