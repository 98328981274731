import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconLayoutVertical: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            d="M22,0H2A2,2,0,0,0,0,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,2.5v3a.5.5,0,0,1-.5.5H2.5A.5.5,0,0,1,2,5.5v-3A.5.5,0,0,1,2.5,2h19A.5.5,0,0,1,22,2.5ZM2,21.5V8.5A.5.5,0,0,1,2.5,8h19a.5.5,0,0,1,.5.5v13a.5.5,0,0,1-.5.5H2.5A.5.5,0,0,1,2,21.5Z"
            fill="currentColor"
        />
    </Icon>
);
