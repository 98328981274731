import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        --tariff-color: ${theme.palette.color.positive.main};
        --tariff-color-light: ${theme.palette.color.positive.opacity.medium};

        border-radius: 2.4rem;
        width: 100%;
        color: var(--tariff-color);
        background-color: transparent;
        padding: 3rem;
        border: 0.1rem solid ${theme.palette.common.border};
    `,
);

export const More = styled.div`
    margin-top: 3rem;
`;
