import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../text/text';

export const OffBoardingStep3: React.FC = observer(() => {
    const { t } = useTranslation();

    return (
        <>
            <Text size="large">{t('dialogs.offboarding.step3.description')}</Text>
        </>
    );
});
