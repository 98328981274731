import React from 'react';
import { observer } from 'mobx-react-lite';
import { ModalPremium } from '../modal/premium/modal-premium';

export interface PremiumDialogInterface {
    opened: boolean;
    close: () => void;
    content: React.ReactElement;
    action?: React.ReactElement;
    locked?: boolean;
    width?: number;
}

export const BasePremiumDialog: React.FC<PremiumDialogInterface> = observer(
    ({ width, opened, close, content, locked }) => {
        return (
            <ModalPremium show={opened} onClose={close} locked={locked} width={width}>
                {content}
            </ModalPremium>
        );
    },
);
