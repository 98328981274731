import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Agree } from './terms-agreement-dialog.styled';
import { Checkbox } from '../../form/checkbox/checkbox';
import { routes } from '../../../../config/routes';
import { Button } from '../../form/button/button';
import { IconNext } from '../../icons/next';
import { IconButton } from '../../icon/button/icon-button';
import { BaseDialog } from '../../../web/dialogs/base-dialog';
import { IconPreferences } from '../../icons/preferences';
import { useRootStore } from '../../../../providers/root-store-provider';

export const TermsAgreementDialog: React.FC = observer(() => {
    const { t } = useTranslation();
    const { termsAgreementDialogStore } = useRootStore();

    return (
        <BaseDialog
            opened={termsAgreementDialogStore.isOpened}
            close={() => termsAgreementDialogStore.closeDialog()}
            showCloseButton={false}
            icon={<IconButton icon={<IconPreferences />} size={'large'} variant={'primary'} />}
            title={
                <Trans
                    i18nKey={'dialogs.termsAgreement.title'}
                    components={{
                        br: <br />,
                    }}
                />
            }
            width={60}
            content={
                <>
                    <Box>
                        <p>{t('dialogs.termsAgreement.perex')}</p>
                        <p>
                            <Trans
                                i18nKey={'dialogs.termsAgreement.info'}
                                components={{
                                    strong: <strong />,
                                }}
                            />
                        </p>
                    </Box>
                    <Agree>
                        <Checkbox
                            label={
                                <>
                                    <Trans
                                        i18nKey={'layout.forms.common.agreeTerms'}
                                        components={{
                                            terms: (
                                                <a
                                                    href={routes.web.termsAndConditions}
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                >
                                                    Link
                                                </a>
                                            ),
                                            privacy: (
                                                <a href={routes.web.privacyPolicy} target={'_blank'} rel="noreferrer">
                                                    Link
                                                </a>
                                            ),
                                        }}
                                    />
                                </>
                            }
                            onChange={() => termsAgreementDialogStore.switchIsCheckboxChecked()}
                            checked={termsAgreementDialogStore.isCheckboxChecked}
                        />
                    </Agree>
                </>
            }
            action={
                <Button
                    variant="primary"
                    size="vlarge"
                    icon={<IconNext />}
                    disabled={!termsAgreementDialogStore.isCheckboxChecked}
                    block
                    onClick={async () => {
                        termsAgreementDialogStore.confirmTerms();
                    }}
                    loading={termsAgreementDialogStore.confirmLoading}
                >
                    {t('dialogs.termsAgreement.button.submit')}
                </Button>
            }
        />
    );
});
