import React from 'react';
import { observer } from 'mobx-react-lite';
import { PortfolioMobxDto } from '../../../../../../../models/mobx/dtos/portfolio/portfolio-mobx-dto';
import { Wrapper } from './portfolios-list-item-simple-regular.styled';
import { PortfoliosListItemSimple } from '../portfolios-list-item-simple';

type PortfoliosListItemRegularType = {
    data: PortfolioMobxDto;
    disabled: boolean;
    onClick?: () => void;
};

export const PortfoliosListItemSimpleRegular: React.FC<PortfoliosListItemRegularType> = observer(
    ({ data, disabled = false, onClick }: PortfoliosListItemRegularType) => {
        return (
            <Wrapper isActive={data.selected} variant={data.colorName} onClick={onClick}>
                <PortfoliosListItemSimple disabled={disabled} data={data} />
            </Wrapper>
        );
    },
);
