import styled, { css } from 'styled-components';
import { PaletteColorType } from '../../../../../../../theme/type/palette-type';
import { getOpacity } from '../../../../../../../theme/utils/get-rgb';
import { Wrapper as PortfoliosListItemSimpleWrapper } from '../portfolios-list-item-simple.styled';

export const Wrapper = styled(PortfoliosListItemSimpleWrapper).withConfig({
    shouldForwardProp: (prop) => !['isActive', 'variant'].includes(prop),
})<{
    isActive: boolean;
    variant: PaletteColorType;
}>`
    ${({ theme, isActive, variant }) => css`
        border: 0.1rem solid ${theme.palette.common.border};
        color: ${theme.palette.color.secondary.main};
        cursor: pointer;

        &:hover {
            border-color: ${getOpacity(theme.palette.color.secondary.main, 0.2)};
        }

        ${isActive &&
        css`
            background: ${getOpacity(theme.palette.color[variant].main, 0.8)};
            color: ${theme.palette.color.white.main};
            cursor: default;
            border-color: transparent;

            &:hover {
                border-color: transparent;
            }
        `}
    `}
`;
