import * as React from 'react';
import { BackButton, LogoPortfolioWrapper, Wrapper } from '../header-horizontal.styled';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { routes } from '../../../../../config/routes';
import { Logo } from '../../../../../components/app/logo/logo';
import { HeaderBackButton } from '../../../../../components/app/header/back-button/header-back-button';

export const HeaderHorizontalShared: React.FC = () => {
    const rootStore = useRootStore();
    const { showBar } = rootStore.themeStore;
    return (
        <Wrapper showBar={showBar}>
            <LogoPortfolioWrapper>
                <Logo href={routes.app.index} inApp />
            </LogoPortfolioWrapper>
            <BackButton>
                <HeaderBackButton />
            </BackButton>
        </Wrapper>
    );
};
