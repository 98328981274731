import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '../../base-dialog';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { SearchSymbolDialogList } from './search-symbol-dialog-list';
import { SearchSymbolDialogTextfield } from './textfield/search-symbol-dialog-textfield';

export const SearchSymbolDialog: React.FC = observer(() => {
    const { t } = useTranslation();
    const { symbolsStore } = useRootStore();
    const { symbolDialogStore } = symbolsStore;

    return (
        <BaseDialog
            opened={symbolDialogStore.searchDialogOpened}
            close={() => symbolDialogStore.closeSearchDialog()}
            title={t('layout.dialogs.watchlist.addSymbol.title')}
            header={<SearchSymbolDialogTextfield />}
            content={<SearchSymbolDialogList />}
            width={86}
        />
    );
});
