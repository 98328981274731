import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconProfits: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            d="M15.4,11.25c.891.648,4.1,3.875,4.1,7.684,0,3.077-3.544,4.316-6.824,4.316S6,22.011,6,18.934C6,14.973,9.5,11.6,10.32,11.1"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M4.5,3.75H6.3c.27,0,.45.214.45.536v6.428c0,.322-.18.536-.45.536H4.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M10.4,2.621,9.155,4.371a1.391,1.391,0,0,1-1.034.517H6.75"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M6.75,9.384c2.042,1.105,3.267,1.866,4.494,1.866H15.4c1.04,0,1.231-.058,1.56-1.125C17.48,8.438,18,5.627,18,5.625c0-.562-.52-1.125-1.559-1.125l-5.027.02-1.6-2.989a.568.568,0,0,1,0-.52.46.46,0,0,1,.4-.261h5.818a.462.462,0,0,1,.405.261.566.566,0,0,1,0,.519L14.858,4.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M11.079,19.094a2.224,2.224,0,0,0,1.858.875c1.139,0,2.063-.693,2.063-1.547s-.924-1.546-2.063-1.546-2.062-.693-2.062-1.548.924-1.547,2.062-1.547a2.221,2.221,0,0,1,1.858.875"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="12.937"
            y1="19.969"
            x2="12.937"
            y2="21"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <line
            x1="12.937"
            y1="12.75"
            x2="12.937"
            y2="13.781"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
    </Icon>
);
