import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconLayoutHorizontal: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            d="M22,0H2A2,2,0,0,0,0,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0ZM2.5,2h4a.5.5,0,0,1,.5.5v19a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5V2.5A.5.5,0,0,1,2.5,2Zm19,20H9.5a.5.5,0,0,1-.5-.5V2.5A.5.5,0,0,1,9.5,2h12a.5.5,0,0,1,.5.5v19A.5.5,0,0,1,21.5,22Z"
            fill="currentColor"
        />
    </Icon>
);
