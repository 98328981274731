import styled, { css } from 'styled-components';

export const Box = styled.div(
    ({ theme }) => css`
        border: 0.1rem solid ${theme.palette.common.border};
        border-radius: ${theme.radius.large};
        padding: ${theme.gap.medium};
        margin-bottom: ${theme.gap.small};

        p {
            margin: 0;
            font-size: ${theme.text.size.xlarge.fontSize};
            line-height: ${theme.text.size.xlarge.lineHeight};
            color: ${theme.palette.common.text};

            & + p {
                margin-top: ${theme.gap.medium};
            }
        }
    `,
);

export const Agree = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: center;

        label {
            margin-right: 0;
        }

        a {
            color: ${theme.palette.common.text};
            text-decoration: underline;
        }
    `,
);
