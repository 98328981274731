import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['image', 'dark'].includes(prop),
})<{
    image?: string;
    dark: boolean;
}>`
    ${({ theme, image, dark }) => css`
        border: 0.1rem solid ${dark ? theme.palette.color.secondary.main : theme.palette.common.border};
        background-color: ${dark ? theme.palette.color.secondary.main : theme.palette.background.body};
        border-radius: ${theme.radius.medium};
        box-shadow: ${theme.shadow.large};
        color: ${dark ? theme.palette.common.textInverse : theme.palette.common.text};
        padding: 1.6rem;
        margin-bottom: ${theme.gap.medium};

        @media ${theme.breakpoints.md.min} {
            padding: 3.2rem;
        }

        @media ${theme.breakpoints.xl.min} {
            padding: 6.4rem;
        }

        ${image &&
        css`
            background-image: url(${image});
            background-repeat: no-repeat;
            background-position: 100% 0;
            background-size: auto 8rem;

            @media ${theme.breakpoints.md.min} {
                background-size: auto 14rem;
            }

            @media ${theme.breakpoints.lg.min} {
                background-size: unset;
            }
        `}
    `}
`;

export const Main = styled.div.withConfig({
    shouldForwardProp: (prop) => !['description'].includes(prop),
})<{
    description?: boolean;
}>`
    ${({ theme, description }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.gap.small};
        padding-right: ${theme.gap.large};

        @media ${theme.breakpoints.lg.min} {
            display: grid;
            padding-right: unset;
            gap: ${theme.gap.small};
            grid-template-columns: ${description ? '1fr' : '1fr 1.6fr 1fr'};
        }
    `}
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Item = styled.div`
    ${({ theme }) => css`
        display: flex;
        padding: ${theme.gap.xsmall} 0;
        align-items: center;
        gap: ${theme.gap.xsmall};
    `}
`;
export const Row = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: ${theme.gap.small};
        margin-bottom: ${theme.gap.medium};

        @media ${theme.breakpoints.lg.min} {
            gap: ${theme.gap.xlarge};
            grid-template-columns: 1fr 1fr;
        }
    `}
`;

export const Column = styled.div`
    ${({ theme }) => css`
        display: flex;
        gap: ${theme.gap.small};

        @media ${theme.breakpoints.lg.min} {
            gap: ${theme.gap.large};
        }

        a {
            color: ${theme.palette.color.purple.main};
            text-decoration: underline;
            transition: color ${theme.base.transition};

            &:hover {
                text-decoration: none;
                color: ${theme.palette.common.textInverse};
            }
        }
    `}
`;

export const Number = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 4rem;
        height: 4rem;
        min-width: 4rem;
        font-size: 1.6rem;
        font-weight: 700;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        color: ${theme.palette.color.purple.light};
        border: 0.1rem solid ${theme.palette.color.secondary.darken};

        @media ${theme.breakpoints.lg.min} {
            width: 6rem;
            height: 6rem;
            min-width: 6rem;
            font-size: 2.4rem;
        }
    `}
`;

export const Name = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.headline.size.h3.fontSize};
        line-height: ${theme.headline.size.h3.lineHeight};
        margin-bottom: ${theme.gap.xsmall};
        font-weight: 700;

        @media ${theme.breakpoints.md.min} {
            font-size: ${theme.headline.size.h2.fontSize};
            line-height: ${theme.headline.size.h2.lineHeight};
        }
    `}
`;

export const More = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.color.purple.main};
        gap: ${theme.gap.xsmall};
        font-weight: 700;
        cursor: pointer;
    `}
`;

export const Description = styled.div`
    ${({ theme }) => css`
        font-size: 1.6rem;
        line-height: 2.2rem;

        @media ${theme.breakpoints.md.min} {
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
    `}
`;

export const ExtraContent = styled.div`
    ${({ theme }) => css`
        padding-top: ${theme.gap.medium};

        @media ${theme.breakpoints.lg.min} {
            padding-right: 22rem;
        }

        @media ${theme.breakpoints.xl.min} {
            padding-right: unset;
        }
    `}
`;
