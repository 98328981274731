import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconSettings: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <path
            d="M10.546,2.438a1.957,1.957,0,0,0,2.908,0L14.4,1.4a1.959,1.959,0,0,1,3.41,1.413l-.071,1.4A1.958,1.958,0,0,0,19.79,6.267l1.4-.071A1.959,1.959,0,0,1,22.6,9.606l-1.042.94a1.96,1.96,0,0,0,0,2.909l1.042.94a1.959,1.959,0,0,1-1.413,3.41l-1.4-.071a1.958,1.958,0,0,0-2.056,2.056l.071,1.4A1.959,1.959,0,0,1,14.4,22.6l-.941-1.041a1.959,1.959,0,0,0-2.908,0L9.606,22.6A1.959,1.959,0,0,1,6.2,21.192l.072-1.4a1.958,1.958,0,0,0-2.056-2.056l-1.4.071A1.958,1.958,0,0,1,1.4,14.4l1.041-.94a1.96,1.96,0,0,0,0-2.909L1.4,9.606A1.958,1.958,0,0,1,2.809,6.2l1.4.071A1.958,1.958,0,0,0,6.267,4.211L6.2,2.81A1.959,1.959,0,0,1,9.606,1.4Z"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <circle
            cx="12"
            cy="12.001"
            r="4.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
    </Icon>
);
