import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StornoReasonEnum } from '@app/common';
import { RadioGroup } from '@mui/material';
import { Radio } from '../../../form/radio/radio';
import { Wrapper } from './offboarding-step2.styled';
import { TextField } from '../../../form/textfield/textfield';
import { useRootStore } from '../../../../../providers/root-store-provider';

export const OffBoardingStep2: React.FC = observer(() => {
    const { t } = useTranslation();
    const { offboardingStore } = useRootStore();

    const onFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        offboardingStore.setStornoReasonDescription(e.target.value);
    };

    const handleChooseOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        offboardingStore.setStornoReason((event.target as HTMLInputElement).value as StornoReasonEnum);
    };

    return (
        <Wrapper>
            <RadioGroup name="reason" value={offboardingStore.stornoReason} onChange={handleChooseOption}>
                <Radio label={t('dialogs.offboarding.step2.option.highPrice')} value={StornoReasonEnum.HighPrice} />
                <Radio
                    label={t('dialogs.offboarding.step2.option.unusedPremiumFeatures')}
                    value={StornoReasonEnum.DoNotUsePremiumFeatures}
                />
                <Radio
                    label={t('dialogs.offboarding.step2.option.competition')}
                    value={StornoReasonEnum.UsingAlternativeService}
                />
                {offboardingStore.stornoReason === StornoReasonEnum.UsingAlternativeService && (
                    <TextField
                        label={t('dialogs.offboarding.step2.option.competitionSpecific')}
                        onChange={onFieldValueChange}
                        required
                    />
                )}
                <Radio
                    label={t('dialogs.offboarding.step2.option.doneWithInvesting')}
                    value={StornoReasonEnum.DoNotInvest}
                />
                <Radio label={t('dialogs.offboarding.step2.option.other')} value={StornoReasonEnum.Other} />
                {offboardingStore.stornoReason === StornoReasonEnum.Other && (
                    <TextField
                        label={t('dialogs.offboarding.step2.option.otherSpecific')}
                        onChange={onFieldValueChange}
                        required
                    />
                )}
            </RadioGroup>
            {/* <More>
                <Button href={'/'} variant="primary" shadow gradient block size={{ xs: 'vsmall', md: 'medium' }}>
                    {t('dialogs.offboarding.step2.fillQuestionnaire')}
                </Button>
            </More> */}
        </Wrapper>
    );
});
