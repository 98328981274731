import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconTrades: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <g>
            <g>
                <line
                    x1="20.29"
                    y1="14.19"
                    x2="14.75"
                    y2="15.29"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                />
                <path
                    d="M3.68,14.2H6.13l3.51,3.94a1.41,1.41,0,0,0,1,.46,46.18,46.18,0,0,0,5.74-4.89c.11-.31-.1-.62-.29-.88L13.58,9.59"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                />
                <path
                    d="M10.58,7.08l-.35-.3A2.16,2.16,0,0,0,9,6.38a2.08,2.08,0,0,0-.78.15L3.75,8.31"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                />
                <path
                    d="M.75,6.6h2a1,1,0,0,1,1,.92v6.42a1,1,0,0,1-1,.91h-2"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                />
                <path
                    d="M23.25,14.85h-2a1,1,0,0,1-1-.91V7.52a1,1,0,0,1,1-.92h2"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                />
            </g>
            <path
                d="M14.75,9.2,11.3,10.34a1.61,1.61,0,0,1-2-.86,1.63,1.63,0,0,1,.74-2.14l3.36-1.69a2.3,2.3,0,0,1,1.06-.25,2.45,2.45,0,0,1,.8.14l5,1.82"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5px"
            />
        </g>
    </Icon>
);
